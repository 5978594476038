import roundNumber from './roundNumber.js'
import moment from 'moment'

const subtractValueFromAverage = (value, average, n) => (average*n - value)/(n - 1)

export default ({statistic, bet, timeSpan}) => {

    let returns = roundNumber(bet.status === "won" ? bet.stake * Number(bet.odds) : bet.status === "lost" ? 0 : bet.stake)
    let profit = roundNumber(returns - bet.stake)

    let newStatistic = {
        avrgOdds: roundNumber(subtractValueFromAverage(bet.odds, statistic.avrgOdds, statistic.betsNum)),
        avrgStake: roundNumber(subtractValueFromAverage(bet.stake, statistic.avrgStake, statistic.betsNum)),
        betsNum: statistic.betsNum - 1,
        graphData : {...statistic.graphData},
        profit: roundNumber(statistic.profit - profit),
        returns: roundNumber(statistic.returns - returns),
        stakeSum : roundNumber(statistic.stakeSum - bet.stake),
        winNum : bet.status === "won" ? statistic.winNum-1 : statistic.winNum
    }

    let graphDataKey = null

    if(timeSpan === "overall"){
        graphDataKey = moment.unix(bet.matchTime).month()
    }else if(timeSpan === "monthly"){
        graphDataKey = moment.unix(bet.matchTime).date() 
    }else if(timeSpan === "weekly"){
        graphDataKey = moment.unix(bet.matchTime).weekday() 
    }
  
    newStatistic.graphData[graphDataKey] = roundNumber(statistic.profit - profit)
  
    return newStatistic

}