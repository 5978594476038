/// LOGIN USER DATA ///
export const SET_AUTH = "SET_AUTH"
export const SET_AUTH_ACCESS_TOKEN = "SET_AUTH_ACCESS_TOKEN"
export const UPDATE_AUTH_USER_INFO = "UPDATE_AUTH_USER_INFO"

/// PAGES INFO CRUD ///
export const FETCH_PAGES_INFO = "FETCH_PAGES_INFO"
export const ADD_PAGE_INFO = "ADD_PAGE_INFO"

/// PAGES CRUD ///
export const FETCH_ALL_PAGES = "FETCH_ALL_PAGES"
export const FETCH_PAGE = "FETCH_PAGE"
export const ADD_NEW_PAGE = "ADD_NEW_PAGE"
export const DELETE_PAGE = "DELETE_PAGE"
export const UPDATE_PAGE = "UPDATE_PAGE"

/// ELEMENTS CRUD ///
export const FETCH_PAGE_ELEMENTS = "FETCH_PAGE_ELEMENTS"
export const ADD_PAGE_ELEMENT = "ADD_PAGE_ELEMENT"
export const SET_ROOT_ELEMENT = "SET_ROOT_ELEMENT"
export const ADD_ROOT_PAGE_ELEMENT = "ADD_ROOT_PAGE_ELEMENT"
export const EDIT_PAGE_ELEMENT = "EDIT_PAGE_ELEMENT"
export const EDIT_PAGE_ELEMENT_CONTENT = "EDIT_PAGE_ELEMENT_CONTENT"
export const EDIT_PAGE_ELEMENT_CHILDREN = "EDIT_PAGE_ELEMENT_CHILDREN"

export const FETCH_GLOBAL_ELEMENTS = "FETCH_GLOBAL_ELEMENTS"

export const DELETE_PAGE_ELEMENT = "DELETE_PAGE_ELEMENT"
export const UPDATE_ELEMENTS_FROM_CHANGES = "UPDATE_ELEMENTS_FROM_CHANGES"

/// CUSTOM CONTAINER CRUD ////
export const FETCH_ALL_CUSTOM_CONTAINERS = "FETCH_ALL_CUSTOM_CONTAINERS"

/// SUBSCRIPTIONS CRUD //
export const FETCH_ALL_SUBSCRIPTIONS = "FETCH_ALL_SUBSCRIPTIONS"

///  USERS CRUD ////
export const FETCH_USERS_BY_MAIL = "FETCH_USERS_BY_MAIL"
export const FETCH_USERS = "FETCH_USERS"
export const FETCH_USER = "FETCH_USER"

export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_INFO = "UPDATE_USER_INFO"

/// WEBPAGE VARIABLES ///
export const SET_SELECTED_PAGE_ELEMENTS = "SET_SELECTED_PAGE_ELEMENTS"
export const SET_SELECTED_PAGE = "SET_SELECTED_PAGE"

export const SET_ACTIVE_MODAL = "SET_ACTIVE_MODAL"
export const SET_PREVIOUS_MODAL = "SET_PREVIOUS_MODAL"
export const SET_MODAL_DATA = "SET_MODAL_DATA"
export const CLOSE_ACTIVE_MODAL = "CLOSE_ACTIVE_MODAL"

export const SET_ACTIVE_SNACKBAR = "SET_ACTIVE_SNACKBAR"
export const CLOSE_ACTIVE_SNACKBAR = "CLOSE_ACTIVE_SNACKBAR"

export const SET_ACTIVE_DRAWER = "SET_ACTIVE_DRAWER"
export const SET_ACTIVE_DOCUMENT_PAGE = "SET_ACTIVE_DOCUMENT_PAGE"
export const GLOBAL_ELEMENTS_LOADED = "GLOBAL_ELEMENTS_LOADED"

export const SET_USER_GEO_LOCATION = "SET_USER_GEO_LOCATION"

/// CONTROL PANEL  ////
export const SET_SELECTED_ELEMENT = "SET_SELECTED_ELEMENT"
export const SET_COPY_SELECTED_ELEMENT = "SET_COPY_SELECTED_ELEMENT"
export const SET_DISABLE_ELEMENT_EVENTS = "SET_DISABLE_ELEMENT_EVENTS"
export const SET_SHOW_ALL_ELEMENTS = "SET_SHOW_ALL_ELEMENTS"

export const SET_ADD_SELECTED_CUSTOM_CONTAINER = "SET_ADD_SELECTED_CUSTOM_CONTAINER"
export const SET_SELECTED_CUSTOM_CONTAINER = "SET_SELECTED_CUSTOM_CONTAINER"

export const FETCH_TIPSTERS = "FETCH_TIPSTERS"
export const UPDATE_TIPSTER_ACHIEVEMENTS = "UPDATE_TIPSTER_ACHIEVEMENTS" 

export const FETCH_TIPSTERS_ACHIVEMENTS_OPTIONS = "FETCH_TIPSTERS_ACHIVEMENTS_OPTIONS"
export const ADD_TIPSTERS_ACHIVEMENTS_OPTION = "ADD_TIPSTERS_ACHIVEMENTS_OPTION"
export const UPDATE_TIPSTERS_ACHIVEMENTS_OPTION = "UPDATE_TIPSTERS_ACHIVEMENTS_OPTION"
export const DELETE_TIPSTERS_ACHIVEMENTS_OPTION = "DELETE_TIPSTERS_ACHIVEMENTS_OPTION"

/// SERVER INFO ////
export const FETCH_SERVER_INFO = "FETCH_SERVER_INFO"

/// SCRAPER INFO ///
export const FETCH_SCRAPER_INFO = "FETCH_SCRAPER_INFO"
export const UPDATE_SCRAPER_SETTINGS = "UPDATE_SCRAPER_SETTINGS"

export const FETCH_ODDSPORTALIDS = "FETCH_ODDSPORTALIDS"
export const UPDATE_ODDSPORTALIDS = "UPDATE_ODDSPORTALIDS"

/// EDIT WRAPPER ///
export const SET_EDIT_WRAPPER__ADD_SELECTED_CUSTOM_CONTAINER = "SET_EDIT_WRAPPER__ADD_SELECTED_CUSTOM_CONTAINER"

/// PAGE CHANGE HISTORY ///
export const UNDO_PAGE_CHANGE_HISTORY = "UNDO_PAGE_CHANGE_HISTORY"
export const REDO_PAGE_CHANGE_HISTORY = "REDO_PAGE_CHANGE_HISTORY"
export const ADD_CHANGE_PAGE_CHANGE_HISTORY = "ADD_CHANGE_PAGE_CHANGE_HISTORY"
export const CLEAR_CHANGES_PAGE_CHANGE_HISTORY = "CLEAR_CHANGES_PAGE_CHANGE_HISTORY"

//// DICTIONARY / LANGUAGE ////////////////
export const SET_ACTIVE_LANGUAGE = "SET_ACTIVE_LANGUAGE"
export const FETCH_DICTIONARY = "FETCH_DICTIONARY"
export const ADD_DICTIONARY_LANGUAGE = "ADD_DICTIONARY_LANGUAGE"
export const FETCH_DICTIONARY_LANGUAGES = "FETCH_DICTIONARY_LANGUAGES"
export const FETCH_DICTIONARY_LANGUGAE_DATA = "FETCH_DICTIONARY_LANGUGAE_DATA"

export const DELETE_DICTIONARY_LANGUAGE = "DELETE_DICTIONARY_LANGUAGE"
export const UPDATE_ELEMENT_LANGUAGE = "UPDATE_ELEMENT_LANGUAGE"

/// BRDOPARA TYPES ////
export const FETCH_TODAYS_MATCHES_SPORT_LIST = "FETCH_TODAYS_MATCHES_SPORT_LIST"
export const FETCH_TODAYS_MATCHES_COMPETITIONS_LIST = "FETCH_TODAYS_MATCHES_COMPETITIONS_LIST"
export const FETCH_TODAYS_MATCHES_COMPETITION = "FETCH_TODAYS_MATCHES_COMPETITION"
export const UPDATE_TODAYS_MATCHES_MATCH_ODDS = "UPDATE_TODAYS_MATCHES_MATCH_ODDS"

export const FETCH_MONTHLY_LEADER_BOARD = "FETCH_MONTHLY_LEADER_BOARD"
export const FETCH_WEEKLY_LEADER_BOARD = "FETCH_WEEKLY_LEADER_BOARD"
export const FETCH_OVERALL_LEADER_BOARD = "FETCH_OVERALL_LEADER_BOARD"

/// USER STATISTIC
export const FETCH_WEEKLY_USER_STATISTIC = "FETCH_WEEKLY_USER_STATISTIC"
export const FETCH_WEEKLY_USERS_STATISTIC = "FETCH_WEEKLY_USERS_STATISTIC"

export const FETCH_MONTHLY_USER_STATISTIC = "FETCH_MONTHLY_USER_STATISTIC"
export const FETCH_MONTHLY_USERS_STATISTIC = "FETCH_MONTHLY_USERS_STATISTIC"

export const FETCH_OVERALL_USER_STATISTIC = "FETCH_OVERALL_USER_STATISTIC"
export const FETCH_OVERALL_USERS_STATISTIC = "FETCH_OVERALL_USERS_STATISTIC"

/// ACTIVE BET INFO
export const FETCH_ACTIVE_BET_BY_ID = "FETCH_ACTIVE_BET_BY_ID"
export const FETCH_ACTIVE_BET_INFO_BY_ID = "FETCH_ACTIVE_BET_INFO_BY_ID"
export const FETCH_ACTIVE_BETS_INFO = "FETCH_ACTIVE_BETS_INFO"
export const FETCH_ACTIVE_BETS_INFO_BY_USER_ID = "FETCH_ACTIVE_BETS_INFO_BY_USER_ID"

/// ACTIVE BETS
export const FETCH_ACTIVE_BETS = "FETCH_ACTIVE_BETS"

export const FETCH_SUBSCRIBED_ACTIVE_BETS = "FETCH_SUBSCRIBED_ACTIVE_BETS"
export const FETCH_SUBSCRIBED_ACTIVE_BET = "FETCH_SUBSCRIBED_ACTIVE_BET"

export const FETCH_USER_ACTIVE_BETS = "FETCH_USER_ACTIVE_BETS"

export const ADD_ACTIVE_USER_BET = "ADD_ACTIVE_USER_BET"

export const UPDATE_ACTIVE_BET = "UPDATE_ACTIVE_BET"

// INACTIVE BETS
export const FETCH_INACTIVE_BETS = "FETCH_INACTIVE_BETS"
export const UPDATE_INACTIVE_BET = "UPDATE_INACTIVE_BET"
export const REMOVE_INACTIVE_BETS = "REMOVE_INACTIVE_BETS"
export const ADD_INACTIVE_BET = "ADD_INACTIVE_BET"

/// FAVORITE BETS
export const FETCH_USER_FAVORITE_BETS = "FETCH_USER_FAVORITE_BETS"
export const REMOVE_USER_FAVORITE_BET = "REMOVE_USER_FAVORITE_BET"
export const ADD_USER_FAVORITE_BET = "ADD_USER_FAVORITE_BET"

/// ACTIVE BET STATISTIC
export const FETCH_ACTIVE_BETS_STATISTIC = "FETCH_ACTIVE_BETS_STATISTIC"
export const ADD_BETS_STATISTIC_COUNTER = "ADD_BETS_STATISTIC_COUNTER"
export const FETCH_ACTIVE_BETS_SPORT_COUNTERS = "FETCH_ACTIVE_BETS_SPORT_COUNTERS"

/// BETS HISTORY
export const FETCH_USER_BETS_HISTORY = "FETCH_USER_BETS_HISTORY"
export const FETCH_BETS_HISTORY = "FETCH_BETS_HISTORY"
export const FETCH_BET_HISTORY = "FETCH_BET_HISTORY"

export const REMOVE_HISTORY_BET = "REMOVE_HISTORY_BET"

///USER METADATA 
export const UPDATE_AUTH_USER_SUBSCRIPTION = "UPDATE_AUTH_USER_SUBSCRIPTION"

//USERs SUPPORT TICKETS
export const FETCH_USERS_SUPPORT_TICKETS = "FETCH_USERS_SUPPORT_TICKETS"
export const REMOVE_USERS_SUPPORT_TICKET = "REMOVE_USERS_SUPPORT_TICKET"
