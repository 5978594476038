import {
    FETCH_MONTHLY_USER_STATISTIC,
    FETCH_MONTHLY_USERS_STATISTIC,
} from "../../../actions/types";
  
  
export default (state = "loading", action) => {
    
    //initialize todays matches object on first load
    let usersStatistic = state !== "loading" ? {...state} : {}
    switch (action.type){
        case FETCH_MONTHLY_USER_STATISTIC:
            usersStatistic[action.userId] = action.payload
            return usersStatistic;
        case FETCH_MONTHLY_USERS_STATISTIC:
            usersStatistic = action.payload
            return usersStatistic;
        default:
            return state
    }
};