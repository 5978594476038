import * as firebase from '../../../../config/firebase'
import logger from  './../../../../helpers/logger'
import moment from 'moment'

import subtractBetFromStatistic from './../../../../helpers/subtractBetFromStatistic'
import objectToFirebaseUpdateObject from './../../../../helpers/objectToFirebaseUpdateObject'

import {
    FETCH_SUBSCRIBED_ACTIVE_BETS,
    FETCH_SUBSCRIBED_ACTIVE_BET,
    FETCH_ACTIVE_BETS,
    FETCH_USER_ACTIVE_BETS,

    FETCH_ACTIVE_BET_INFO_BY_ID,
    FETCH_ACTIVE_BETS_INFO,
    
    FETCH_USER_BETS_HISTORY,
   
    FETCH_BETS_HISTORY,
    FETCH_BET_HISTORY,
    REMOVE_HISTORY_BET,

    FETCH_ACTIVE_BETS_STATISTIC,
    FETCH_ACTIVE_BETS_SPORT_COUNTERS,
     
    FETCH_USER_FAVORITE_BETS,
    ADD_ACTIVE_USER_BET,
    ADD_USER_FAVORITE_BET,
    REMOVE_USER_FAVORITE_BET,
   
    SET_ACTIVE_SNACKBAR,

    ADD_BETS_STATISTIC_COUNTER,

    UPDATE_ACTIVE_BET,
    CLOSE_ACTIVE_MODAL,

    FETCH_INACTIVE_BETS,
    UPDATE_INACTIVE_BET,
    REMOVE_INACTIVE_BETS,    
    ADD_INACTIVE_BET,

} from "../../types";





export const fetchSubscribedActiveBetById = (betId) => async dispatch => {
    
    try{
        logger('api_call', "fetchSubscribedActiveBetById")

        let res = await firebase.betsRef.child('activeBets').child(betId).once('value')
        let result = res.val()

        dispatch({
            type: FETCH_SUBSCRIBED_ACTIVE_BET,
            betId,
            payload: result
        })
    }catch(err){
        logger('exception', "fetchSubscribedActiveBetById", {err})
        
    }
}

export const fetchActiveBets = ({startAt = null, limitToLast = 300}) => async dispatch => {

    try{
        logger('api_call', "fetchActiveBets")

        let res = null

        if(startAt) res = await firebase.betsRef.child(`activeBets`).orderByKey().startAt(startAt).limitToLast(limitToLast).once('value')
        else res = await firebase.betsRef.child(`activeBets`).orderByKey().limitToLast(limitToLast).once('value')

        let result = res.val() 
        dispatch({
            type: FETCH_ACTIVE_BETS,
            payload: result
        })
    }catch(err){
        logger('exception', "fetchActiveBets", {err})
    }
}


export const updateActiveBet = ({betKey, values}) => async dispatch => {
    
    try{
        logger('api_call', "updateActiveBet")
       
        await firebase.betsRef.child(`activeBets`).child(betKey).update(values)

        dispatch({
            type: UPDATE_ACTIVE_BET,
            betKey,
            payload: values
        })

        dispatch({
            type: CLOSE_ACTIVE_MODAL,
        })

    }catch(err){
        logger('exception', "updateActiveBet", {err})
    }
}



export const fetchInactiveBets = ({startAt = null, limitToLast = 300}) => async (dispatch, getState) => {

    try{
        let inactiveBetsRef = firebase.betsRef.child(`inactiveBets`)

        inactiveBetsRef.on('child_added', (data) => {
           
            if(getState().brdoparaData.inactiveBets !== "loading"){
                logger('api_call', "fetchAddInactiveBet")

                let betKey = data.key
                let betData = data.val()
                
                dispatch({
                    type: ADD_INACTIVE_BET,
                    betKey, 
                    payload: betData
                })
            }   
          
           
        });
        
        
        if(getState().brdoparaData.inactiveBets === "loading"){
            logger('api_call', "fetchInactiveBets")
            let res = await inactiveBetsRef.once('value')

            let data = res.val()

            dispatch({
                type: FETCH_INACTIVE_BETS,
                payload: data
            })
        }
    
        
        

        inactiveBetsRef.on('child_changed', (data) => {
            logger('api_call', "fetchUpdateInactiveBet")
            let betKey = data.key
            let betData = data.val()

            dispatch({
                type: UPDATE_INACTIVE_BET,
                betKey, 
                payload: betData
            })
        });
        
        inactiveBetsRef.on('child_removed', (data) => {
            logger('api_call', "fetchRemoveInactiveBet")
            let betKey = data.key

            dispatch({
                type: REMOVE_INACTIVE_BETS,
                betKey
            })

        });
          



    }catch(err){
        logger('exception', "fetchInactiveBets", {err})
    }
}


export const removeInactiveBet = (betKey) => async dispatch => {

    try{
        logger('api_call', "removeInactiveBet")

    
        await firebase.betsRef.child(`inactiveBets`).child(betKey).remove()
  
        dispatch({
            type: REMOVE_INACTIVE_BETS,
            betKey
        })

    }catch(err){
        logger('exception', "removeInactiveBet", {err})
    }
}



export const updateInactiveBet = ({betKey, values}) => async dispatch => {
    
    try{
        logger('api_call', "updateInactiveBet")

        await firebase.betsRef.child(`inactiveBets`).child(betKey).update(values)

        

    }catch(err){
        logger('exception', "updateInactiveBet", {err})
    }
}






///ACTIVE BETS INFO ACTIONS

export const fetchActiveBetInfoById = (betId) => async dispatch => {
    
   
    try{
        logger('api_call', "fetchActiveBetInfoById")

        let res = await firebase.betsRef.child(`activeBetsInfo/${betId}`).once('value')
        let result = res.val()
       
        dispatch({
            type: FETCH_ACTIVE_BET_INFO_BY_ID,
            betId,
            payload: result
        })
    }catch(err){
        logger('exception', "fetchActiveBetInfoById", {err})
    }
}

export const fetchActiveBetsInfo = ({orderBy, startAt=" ", groupBy}) => async (dispatch, getState) => {
   
    try{
        logger('api_call', "fetchActiveBetsInfo")
        
        let res = null
        if(orderBy !== "time"){
            if(groupBy){
              
                res = await firebase.betsRef.child(`activeBetsInfo`).orderByChild(orderBy).equalTo(groupBy).limitToLast(20).once('value')
                
            }else{
                res = await firebase.betsRef.child(`activeBetsInfo`).orderByChild(orderBy).limitToLast(20).once('value')
            }
           
        }else{
            res = await firebase.betsRef.child(`activeBetsInfo`).orderByKey().startAt(startAt).limitToLast(20).once('value')
        }
        
        let result = res.val()

        ///fetch bets statistic
     
        dispatch({
            type: FETCH_ACTIVE_BETS_INFO,
            orderBy, 
            payload: result
        })

    }catch(err){
        logger('exception', "fetchActiveBetsInfo", {err})
    }
}

export const fetchActiveBetsInfoByUserId = ({auth, userId, orderBy, startAt=""}) => async dispatch => {
    
    try{
        logger('api_call', "fetchActiveBetsInfoByUserId")

        const results = await firebase.betsRef.child('activeBetsInfo').orderByChild('user').equalTo(userId).once('value')
     
        dispatch({
            type: FETCH_ACTIVE_BETS_INFO,
            userId,
            orderBy,
            payload: results.val()
        })

        return true
    }catch(err){
        logger('exception', "fetchActiveBetsInfoByUserId", {err})
    }
}

export const fetchActiveBetsInfoBySport = ({auth, userId, orderBy, groupBy="", startAt="", betId}) => async dispatch => {
    
    try{
        logger('api_call', "fetchActiveBetsInfoBySport")

        let res = await firebase.betsRef.child('activeBetsInfo').orderByChild(orderBy).equalTo(groupBy).once('value')
        let results = res.val()
        if(!results) return 
     
        dispatch({
            type: FETCH_ACTIVE_BETS_INFO,
            userId,
            orderBy,
            payload: results
        })

        return true
    }catch(err){
        logger('exception', "fetchActiveBetsInfoBySport", {err})
    }
}

///// BETS STATISTIC ACTION
const asyncfetchActiveBetsStatistic = async () => {
    
    try{
        logger('api_call', "fetchActiveBetsStatistic")

        let res = await firebase.betsRef.child('betsStats').once('value')
        let result = res.val()
      
       return result
    }catch(err){
        logger('exception', "fetchActiveBetsStatistic", {err})
    }
}

export const fetchActiveBetsStatistic =  () => async dispatch => {
    
    try{
        logger('api_call', "fetchActiveBetsStatistic")

        let res = await firebase.betsRef.child('betsStats').once('value')
        let result = res.val()

        dispatch({
            type: FETCH_ACTIVE_BETS_STATISTIC,
            groupBy: "all",
            payload: result
        })

       return 
    }catch(err){
        logger('exception', "fetchActiveBetsStatistic", {err})
    }
}

export const fetchActiveBetsSportCounters =  () => async dispatch => {
    
    try{
        logger('api_call', "fetchActiveBetsSportCounters")

        let res = await firebase.betsRef.child('betsStats').child('sportsCounters').once('value')
        let result = res.val()

        dispatch({
            type: FETCH_ACTIVE_BETS_SPORT_COUNTERS,
            groupBy: "all",
            payload: result
        })

       return 
    }catch(err){
        logger('exception', "fetchActiveBetsSportCounters", {err})
    }
}

///ACTIVE USER BETS ACTIONS
export const fetchSubscribedActiveBets = ({auth}) => async dispatch => {
    
    try{
        logger('api_call', "fetchSubscribedActiveBets")

        let subscriptions = auth.userInfo && auth.userInfo.metadata && auth.userInfo.metadata.subscriptions
       
        if(subscriptions){
            
            let subscribedBets = {}

            let promises = Object.keys(subscriptions).map(s => firebase.betsRef.child('activeBets').orderByChild('user').equalTo(s).once('value'))
            
            let resPromises = await Promise.all(promises)

            subscribedBets = resPromises.reduce((reducer, res)=> ({...reducer, ...res.val()}),{})
            //console.log(subscribedBets)
            const res = await firebase.betsRef.child("usersFavorites").child(auth.uid).once('value')
            
            let favActiveBetsId = res.val()
            favActiveBetsId = favActiveBetsId ? favActiveBetsId:{}
            const userFavoriteBets = Object.keys(favActiveBetsId).reduce((reducer, betId) => ({...reducer, [betId]: subscribedBets[betId]}), {})
            
            /// create subscribed bets statistic
            let subscribedBetStatistic = Object.keys(subscribedBets).reduce((reducer, betId) => {
                let bet = subscribedBets[betId]

                if(reducer.sportsCounters[bet.sportId])reducer.sportsCounters[bet.sportId] += 1
                else reducer.sportsCounters[bet.sportId] = 1
          
                if(reducer.competitionsCounters[bet.sportId]){
                  if(reducer.competitionsCounters[bet.sportId][bet.competitionId])reducer.competitionsCounters[bet.sportId][bet.competitionId] += 1
                  else reducer.competitionsCounters[bet.sportId][bet.competitionId] = 1
                }else reducer.competitionsCounters[bet.sportId] = {[bet.competitionId]: 1}
          
                return reducer
            },{competitionsCounters: {}, sportsCounters: {}})

            /// create favorite bets statistic
            let favoriteBetStatistic = Object.keys(userFavoriteBets).reduce((reducer, betId) => {
                let bet = subscribedBets[betId]
            
                if(reducer.sportsCounters[bet.sportId])reducer.sportsCounters[bet.sportId] += 1
                else reducer.sportsCounters[bet.sportId] = 1
          
                if(reducer.competitionsCounters[bet.sportId]){
                  if(reducer.competitionsCounters[bet.sportId][bet.competitionId])reducer.competitionsCounters[bet.sportId][bet.competitionId] += 1
                  else reducer.competitionsCounters[bet.sportId][bet.competitionId] = 1
                }else reducer.competitionsCounters[bet.sportId] = {[bet.competitionId]: 1}
          
                return reducer
            },{competitionsCounters:{}, sportsCounters:{}})

            dispatch({
                type: FETCH_SUBSCRIBED_ACTIVE_BETS,
                payload: subscribedBets
            })

            dispatch({
                type: FETCH_USER_FAVORITE_BETS,
                payload: userFavoriteBets
            })

         
        }
 
        return true
    }catch(err){
        
        logger('exception', "fetchSubscribedActiveBets", {err})
    }
}

export const fetchUserActiveBets = ({auth}) => async (dispatch, getState) => {
    
    try{
        logger('api_call', "fetchUserActiveBets")

        if(auth.userInfo.type === 1){

            const res = await firebase.favActiveBetsRef.child(auth.uid).once('value')
            const favActiveBetsId = res.val()

            if(favActiveBetsId){
                console.log("No favorite bets selected")
                return 
            }

            let subscribedActiveBets = getState().brdoparaData.activeBets.subscribed

            if(!subscribedActiveBets){
                
                let subscriptions = auth.userInfo && auth.userInfo.metadata && auth.userInfo.metadata.subscriptions
                subscribedActiveBets = {}

                if(subscriptions){
                    for(let s of subscriptions){
                        let res = await firebase.betsRef.child('activeBets').orderByChild('user').equalTo(s).once('value')
                        subscribedActiveBets = {...subscribedActiveBets, ...res.val()}
                    }
                }else{
                    console.log("No subscribed bets")
                    return
                }
            }

            dispatch({
                type: FETCH_USER_ACTIVE_BETS,
                orderBy: "time",
                payload: favActiveBetsId.map(betId => subscribedActiveBets[betId])
            })
        }else if(auth.userInfo.type === 2){

            let res = await firebase.betsRef.child('activeBets').orderByChild('user').equalTo(auth.uid).once('value')
            let result = res.val()
            if(!result)result = {}

            dispatch({
                type: FETCH_USER_ACTIVE_BETS,
                orderBy: "time",
                payload: result
            })
        }
    }catch(err){          
        logger('exception', "fetchUserActiveBets", {err})
    }   
}

//// BETS HISTORY ACTIONS
export const fetchUserBetsHistory = ({auth, userId, orderBy, startAt}) => async dispatch => {
    
    try{
        logger('api_call', "fetchUserBetsHistory")

        let res = null
        if(orderBy === "time"){
            if(startAt){
                res = await firebase.betsRef.child('usersHistory').child(userId).orderByKey().startAt(startAt).limitToLast(20).once('value')
            }else{
                res = await firebase.betsRef.child('usersHistory').child(userId).orderByKey().limitToLast(20).once('value')
            }
            
        }else{
            
            res = await firebase.betsRef.child('usersHistory').child(userId).orderByChild('orderBy').startAt(startAt).limitToLast(20).once('value')
        }
        

        dispatch({
            type: FETCH_USER_BETS_HISTORY,
            userId,
            orderBy: "user",
            payload: res.val()
        })

        return true
    }catch(err){
        
        logger('exception', "fetchUserBetsHistory", {err})
    }
}

export const fetchBetsHistory = ({auth, userId, orderBy, startAt, groupBy, limitToFirst=300}) => async dispatch => {
    
   
    try{
        logger('api_call', "fetchBetsHistory")

        let results = null
        if(groupBy) results = await firebase.betsRef.child('history').orderByChild(orderBy).startAt(groupBy).limitToFirst(limitToFirst).once('value')
        else results = await firebase.betsRef.child('history').orderByChild(orderBy).limitToLast(limitToFirst).once('value')
        
        let data = results.val()
      
        dispatch({
            type: FETCH_BETS_HISTORY,
            userId,
            orderBy,
            payload: data
        })
        
        return true
    }catch(err){
        
        logger('exception', "fetchBetsHistory", {err})
    }
}

export const fetchHistoryBetById = ({betId}) => async dispatch => {
    
    try{
        logger('api_call', "fetchHistoryBetById")

        let res = await firebase.betsRef.child('history').child(betId).once('value')
        let result = res.val()

        dispatch({
            type: FETCH_BET_HISTORY,
            betId,
            payload: result
        })
    }catch(err){
        logger('exception', "fetchHistoryBetById", {err})
    }
}


export const removeHistoryBet = ({bet, betKey, moveToInactive = true}) => async dispatch => {
   
    try{
        logger('api_call', "removeHistoryBet")  

        let usersFavoritesHistory = await firebase.betsRef.child(`usersFavoritesHistory`).child(betKey).once('value')
        usersFavoritesHistory = usersFavoritesHistory.val() || {}

       
        let statisticTimeSpan = ["overall"]
        if(moment.unix(bet.matchTime).month() === moment().month()){
            statisticTimeSpan.push("monthly")
            if(moment.unix(bet.matchTime).week() === moment().week()) statisticTimeSpan.push("weekly")
        }
        
        let tipsterStatistics = {}
        let usersStatistic = {}  

        let usersBetHistory = {
            [`bets/usersHistory/${bet.user}/${betKey}`]: null
        }
        
        for( let timeSpan of statisticTimeSpan){
            let tipsterStatistic = await firebase.usersStatisticRef.child(2).child(timeSpan).child(bet.user).once('value')
            tipsterStatistic = tipsterStatistic.val() || {}
            tipsterStatistics[timeSpan] = {[bet.user]: tipsterStatistic}
        }   
        
        for( let timeSpan of statisticTimeSpan){
            usersStatistic[timeSpan] = {}

            for(let userKey in usersFavoritesHistory){
                let userStatistic = await firebase.usersStatisticRef.child(1).child(timeSpan).child(userKey).once('value')
                usersStatistic[timeSpan][userKey] = userStatistic.val() 

                usersBetHistory[`bets/usersHistory/${userKey}/${betKey}`] = null
            }   
        }
        
        for(let timeSpan in tipsterStatistics){
           
            tipsterStatistics[timeSpan][bet.user] = subtractBetFromStatistic({statistic: tipsterStatistics[timeSpan][bet.user], bet, timeSpan})
        }

        for(let timeSpan in usersStatistic){
            for(let userKey in usersStatistic[timeSpan]){

                usersStatistic[timeSpan][userKey] = subtractBetFromStatistic({statistic: usersStatistic[timeSpan][userKey], bet, timeSpan})
            }
        }

        let usersFavorites = {}
        for(let userKey in usersFavoritesHistory){
            usersFavorites[userKey] = {[betKey]: Date.now()}
        }

        let updatedData = {}
        if(moveToInactive){

            updatedData = {
                [`bets/history/${betKey}`]: null,
                ...usersBetHistory,
                [`bets/inactiveBets/${betKey}`]: bet,
    
                ...objectToFirebaseUpdateObject({bets: {usersFavorites}}),
               
                ...objectToFirebaseUpdateObject({
                    usersStatistic:{
                        1: usersStatistic,
                        2: tipsterStatistics
                    }
                }),
            }
        }else{
            updatedData = {
                [`bets/history/${betKey}`]: null,

                ...usersBetHistory,
                ...objectToFirebaseUpdateObject({
                    usersStatistic:{
                        1: usersStatistic,
                        2: tipsterStatistics
                    }
                }),
            }

        }

        
        
        
        await firebase.databaseRef.update(updatedData)
  
        dispatch({
            type: REMOVE_HISTORY_BET,
            betId: betKey
        })

        dispatch({
            type: CLOSE_ACTIVE_MODAL,
           
        })

    }catch(err){
        logger('exception', "removeHistoryBet", {err})
    }
}

///// ADD NEW ACTIVE BET
export const addUserActiveBet = (auth, bet, stake) => async (dispatch, getState) => {
    
    
    let todaysMatches =  getState().todaysMatches
    let timestamp = Math.floor(Date.now()/1000)
    try{
        logger('api_call', "addUserActiveBet")

        let activeBet = {
            timestamp,
            sportId: bet[0].sportId,
            
            competitionId: bet[0].competitionId,
            matchId: bet[0].matchId,
            type: bet[0].betTypeId,
            typeOption: bet[0].betTypeOptionId,
            bet: bet[0].matchWinner, 
            odds: bet[0].betOdds,
            stake,
            comment: bet[0].comment || null,

            competition: todaysMatches.sports[bet[0].sportId].competitionsList[bet[0].competitionId].title,
            match: todaysMatches.sports[bet[0].sportId].competitions[bet[0].competitionId].matches[bet[0].matchId].participants,
            matchLink: todaysMatches.sports[bet[0].sportId].competitions[bet[0].competitionId].matches[bet[0].matchId].matchLink,
            matchTime: todaysMatches.sports[bet[0].sportId].competitions[bet[0].competitionId].matches[bet[0].matchId].time,
            user: auth.uid,
            userName: auth.userInfo.displayName
        }

        let activeBetInfo = {
            timestamp,
            stake,

            competitionId: bet[0].competitionId,
            sportId: bet[0].sportId,
            matchId: bet[0].matchId,

            competition: todaysMatches.sports[bet[0].sportId].competitionsList[bet[0].competitionId].title,
            match: todaysMatches.sports[bet[0].sportId].competitions[bet[0].competitionId].matches[bet[0].matchId].participants,
            matchTime: todaysMatches.sports[bet[0].sportId].competitions[bet[0].competitionId].matches[bet[0].matchId].time,

            user: auth.uid,
            userName: auth.userInfo.displayName
        }

        let newActiveBetKey = firebase.betsRef.child("activeBets").push().key

        firebase.betsRef.update({
            [`activeBets/${newActiveBetKey}`]: activeBet,
            [`activeBetsInfo/${newActiveBetKey}`]: activeBetInfo,
        })

        dispatch({
            type: ADD_ACTIVE_USER_BET,
            user: auth.uid,
            betId: newActiveBetKey,
            payload: activeBet
        })

        dispatch({
            type: SET_ACTIVE_SNACKBAR,
            name: "addBetSuccess",
            status: "success"
        })

        dispatch({
            type: ADD_BETS_STATISTIC_COUNTER,
            bet: activeBet
        })
        
        return true
    }catch(err){

        dispatch({
            type: SET_ACTIVE_SNACKBAR,
            name: "addBetError",
            status: "error"
        })
        logger('exception', "addUserActiveBet", {err})
       
    }
    
}


////// BETS FAVORITES ACTION
export const addUserFavoriteBet = ({betId, userId}) => async dispatch => {
    
    try{
        logger('api_call', "addUserFavoriteBet")

        await firebase.betsRef.child('usersFavorites').child(userId).update({
            [betId]: Date.now()
        })
        
        dispatch({
            type: ADD_USER_FAVORITE_BET,
            userId,
            betId,
            payload: Date.now()
        })

        dispatch({
            type:  SET_ACTIVE_SNACKBAR,
            name: "addFavoriteBetSnackbar",
            status: "success"
        })

    }catch(err){
        dispatch({
            type:  SET_ACTIVE_SNACKBAR,
            name: "favoriteBetErrorSnackbar",
            status: "error"
        })
        logger('exception', "addUserFavoriteBet", {err})
    }
}

export const removeUserFavoriteBet = ({betId, userId}) => async dispatch => {
    
    try{
        logger('api_call', "removeUserFavoriteBet")
        
        await firebase.betsRef.child('usersFavorites').child(userId).child(betId).remove()

        dispatch({
            type: REMOVE_USER_FAVORITE_BET,
            userId,
            betId
        })

        dispatch({
            type:  SET_ACTIVE_SNACKBAR,
            name: "removeFavoriteBetSnackbar",
            status: "success"
        })

    }catch(err){
        dispatch({
            type:  SET_ACTIVE_SNACKBAR,
            name: "favoriteBetErrorSnackbar",
            status: "error"
        })
        logger('exception', "removeUserFavoriteBet", {err})
    }
}

export const fetchUserFavoriteBet = ({userId}) => async dispatch => {
    
    try{
        logger('api_call', "fetchUserFavoriteBet")

        let res = await firebase.betsRef.child('usersFavorites').child(userId).once('value')
        let results = res.val()

        dispatch({
            type: FETCH_USER_FAVORITE_BETS,
            payload: results
        })

       
    }catch(err){
        logger('exception', "removeUserFavoriteBet", {err})
    }
}
