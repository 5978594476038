
import {
    FETCH_SUBSCRIBED_ACTIVE_BETS,
    FETCH_USER_ACTIVE_BETS,
    FETCH_ACTIVE_BET_BY_ID,
    FETCH_ACTIVE_BET_INFO_BY_ID,
    FETCH_ACTIVE_BETS_INFO,
    ADD_ACTIVE_USER_BET,
    FETCH_ACTIVE_BETS_INFO_BY_USER_ID,
    FETCH_ACTIVE_BETS,
    UPDATE_ACTIVE_BET,

} from "../../../actions/types";
  
  
export default (state = "loading", action) => {
    if(!action.payload)return state

    //initialize todays matches object on first load
    let activeBets = state !== "loading" ? {...state} : {}
   
    switch (action.type) {
        case FETCH_ACTIVE_BETS:
            return action.payload; 
            
        case FETCH_USER_ACTIVE_BETS:
            activeBets ={...activeBets, ...action.payload} 
            return activeBets;

        case FETCH_ACTIVE_BET_BY_ID:
            if(activeBets[action.betId]){
                activeBets[action.betId] = {...activeBets[action.betId], ...action.payload }
            }else activeBets[action.betId] = action.payload
            return activeBets

        case FETCH_ACTIVE_BET_INFO_BY_ID:
            if(activeBets[action.betId]){
                activeBets[action.betId] = {...activeBets[action.betId], ...action.payload }
            }else activeBets[action.betId] = action.payload
            return activeBets

        case FETCH_ACTIVE_BETS_INFO:
            activeBets = {...activeBets, ...action.payload }
            return activeBets
            
        case ADD_ACTIVE_USER_BET:
            activeBets ={...activeBets, [action.betId]: action.payload} 
            return activeBets

        case UPDATE_ACTIVE_BET:
            activeBets[action.betKey] = {...activeBets[action.betKey] , ...action.payload} 
            return activeBets

        default:
            return state
    }
};