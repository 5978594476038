import {
    FETCH_INACTIVE_BETS,
    UPDATE_INACTIVE_BET,
    REMOVE_INACTIVE_BETS,
    ADD_INACTIVE_BET

} from "../../../actions/types";
  
  
export default (state = "loading", action) => {
   
    //initialize todays matches object on first load
    let inactiveBets = state !== "loading" ? {...state} : {}
  
    switch (action.type) {
        case FETCH_INACTIVE_BETS:
            return action.payload; 
        case ADD_INACTIVE_BET:
            inactiveBets[action.betKey] = action.payload
            return inactiveBets
        case REMOVE_INACTIVE_BETS:
            delete inactiveBets[action.betKey]
           
            return inactiveBets; 
        
        case UPDATE_INACTIVE_BET:
            inactiveBets[action.betKey] = action.payload
            return inactiveBets

        default:
            return state
    }
};