import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  root: {

  },

});

const SPINNER_SIZE = 80

function Preloader(props) {
  const { classes, type} = props;
  if(type === "circular"){
    return (
      <div className={classes.root} styles={props.styles || {}}>
          <CircularProgress />
      </div>
    );
  }else{
    return (
      <div className={classes.root}>
          <LinearProgress />
      </div>
    );
  }
  
}

Preloader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Preloader);
