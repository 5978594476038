
import {
  FETCH_ALL_CUSTOM_CONTAINERS,

} from "./../../../actions/types";


export default (state = "loading", action) => {
  switch (action.type) {
    case FETCH_ALL_CUSTOM_CONTAINERS:
      return action.payload;

    default:
      return state;
  }
};
