
import { SET_SELECTED_CUSTOM_CONTAINER} from "./../../../actions/types";


export default (state = "", action) => {
  switch (action.type) {
    case SET_SELECTED_CUSTOM_CONTAINER:
      return action.payload;
    default:
      return state;
  }
};
