
import { SET_COPY_SELECTED_ELEMENT } from "./../../../actions/types";


// if true sets all elements in copy to element mode
// used in elementWraper component
export default (state = false, action) => {
  switch (action.type) {
    case SET_COPY_SELECTED_ELEMENT:
      return action.payload;
    default:
      return state;
  }
};
