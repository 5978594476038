import React from 'react';

import { withStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import LoginForm from './../components/forms/LoginForm'

const styles = theme => ({
    root:{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        background: theme.palette.background.secondary,
        alignItems: "center",
        position: "relative",
    },
 
});


function LoginBoxElement({
    classes,
    auth,
    ...props
}){

    return (
        <Box className={classes.root} p={1}>
           <LoginForm />
        </Box>
    )

}

const mapStateToProps = ({auth}) => {
    return {auth}
  }

export default withStyles(styles)(LoginBoxElement)

