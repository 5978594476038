
import {
    FETCH_TIPSTERS,
    UPDATE_TIPSTER_ACHIEVEMENTS,
  } from "../../../actions/types";
  
  export default (state = "loading", action) => {
    switch (action.type) {
      case FETCH_TIPSTERS:
        return action.payload;
      case UPDATE_TIPSTER_ACHIEVEMENTS:
        state[action.key].achievements = action.payload
        return {...state};
      default:
        return state;
    }
};
  