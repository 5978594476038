
import {
  FETCH_ODDSPORTALIDS,
  UPDATE_ODDSPORTALIDS
} from "../../../actions/types";


export default (state = "loading", action) => {
  switch (action.type) {
    case FETCH_ODDSPORTALIDS:
      return action.payload;
    case UPDATE_ODDSPORTALIDS:
      if(!state[action.sport])return {...state, [action.sport]: action.payload}
      let sportIds = {...state[action.sport], ...action.payload} 
      return {...state, [action.sport]: sportIds}
    default:
      return state;
  }
};
