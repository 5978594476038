
import {
  FETCH_USERS_SUPPORT_TICKETS,
  REMOVE_USERS_SUPPORT_TICKET
  } from "../../../actions/types";
  
  export default (state = "loading", action) => {
    switch (action.type) {
      case FETCH_USERS_SUPPORT_TICKETS:
        return action.payload;
      case REMOVE_USERS_SUPPORT_TICKET:
        let newState = {...state}
        delete newState[action.userId][action.ticketId]
        return newState
      default:
        return state;
    }
};
  