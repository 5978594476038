
import {
  FETCH_TIPSTERS_ACHIVEMENTS_OPTIONS,
  ADD_TIPSTERS_ACHIVEMENTS_OPTION,
  DELETE_TIPSTERS_ACHIVEMENTS_OPTION,
  UPDATE_TIPSTERS_ACHIVEMENTS_OPTION,
} from "../../../actions/types";
  
  export default (state = "loading", action) => {
    switch (action.type) {
      case FETCH_TIPSTERS_ACHIVEMENTS_OPTIONS:
        return action.payload;
      case DELETE_TIPSTERS_ACHIVEMENTS_OPTION:
          delete state[action.language][action.key]
        return {...state}
      case UPDATE_TIPSTERS_ACHIVEMENTS_OPTION:
        state[action.language][action.key] = action.payload
        return {...state}
      case ADD_TIPSTERS_ACHIVEMENTS_OPTION:
        state["SI"] = {...state["SI"], ...action.payload};
        return {...state}
      default:
        return state;
    }
};
  