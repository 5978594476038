import React, {Suspense} from 'react';

import * as actions from './redux/actions'
import {connect} from "react-redux";
import {withRouter} from 'react-router'
import {Route, Switch, Redirect} from 'react-router-dom'
import {makeStyles, createStyles} from '@material-ui/core/styles';
import withRoot from './withRoot';
import Preloader from './controlpanel/components/Preloader'
import Login from './controlpanel/views/Login'
import { auth } from 'firebase';

const ControlPanel = React.lazy(()=> import('./controlpanel/ControlPanel'))

const useStyle = makeStyles( theme => createStyles({

  root: {
    minHeight: "100vh",
    position: "relative",
    textAlign: 'center'
  },

  backgroundImage: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  }

}))

function App({
  isAuthenticated,
  auth
}){
  
  const classes = useStyle()
  const [pageLoaded, setPageLoaded] = React.useState()
 
  React.useEffect(()=>{
    isAuthenticated()

    setPageLoaded(true)
  }, [])
  
  if(auth === "loading") return <Preloader />

  return (
    <div className={classes.root}>
        {
          auth && auth.userInfo && auth.userInfo.admin ?
          <Suspense fallback={<Preloader />}>
            <ControlPanel/>
          </Suspense>:
          <Login />
        }
        
    </div>
  ) 
}

App.propTypes = {

};

const mapStateToProps = ({auth}) => {
  return {auth}
}

export default withRouter(withRoot(connect(mapStateToProps, actions)(App)))

