
import {
  FETCH_USERS,
  FETCH_USER,
  FETCH_USERS_BY_MAIL,
  UPDATE_USER,
  UPDATE_USER_INFO
} from "./../../../actions/types";


export default (state = "loading", action) => {
  let users = state === "loading" ? {} : {...state}
  switch (action.type) {
    case FETCH_USER:
      return {...users, [action.uid]:action.payload};
    case FETCH_USERS:
      return {...users, ...action.payload};
    case FETCH_USERS_BY_MAIL:
      return {...users, ...action.payload}
    case UPDATE_USER:
      users[action.uid] = {...users[action.uid], ...action.payload}
      return {...users};
    default:
      return users;
  }
};
