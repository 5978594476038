import { combineReducers } from "redux";
import pagesReducer from './api/webpage/pagesReducer';
import modalStatusReducer from './component/modalStatusReducer';
import snackbarStatusReducer from './component/snackbarStatusReducer';
import drawerStatusReducer from './component/drawerStatusReducer';

import selectedPageReducer from './session/selectedPageReducer'
import elementsReducer from './api/webpage/elementsReducer'
import authReducer from './session/authReducer'
import pagesInfoReducer from './api/webpage/pagesInfoReducer'
import subscriptionsReducer from './api/tehnofan/subscriptionsReducer'
import usersReducer from './api/webpage/usersReducer'
import activeLanguageReducer from './session/activeLanguageReducer'
import dictionaryReducer from './api/webpage/dictionaryReducer'
import activeDocumentPageReducer from './session/activeDocumentPageReducer'
import globalElementsReducer from './api/webpage/globalElementsReducer'
import serverInfoReducer from './api/controlpanel/serverInfoReducer'
import scraperInfoReducer from './api/controlpanel/scraperInfoReducer'
import scraperOddsportalIdsReducer from './api/controlpanel/scraperOddsportalIdsReducer'
import globalElementsLoadedReducer from './session/globalElementsLoadedReducer'

/// PAGEBUILDER REDUCERS
import selectedElementReducer from './session/pagebuilder/selectedElementReducer'
import pageChangeHistoryReducer from './session/pagebuilder/pageChangeHistoryReducer'
import copySelectedElementReducer from './session/pagebuilder/copySelectedElementReducer'
import disableElementEventsReducer from './session/pagebuilder/disableElementEventsReducer'
import showAllElementsReducer from './session/pagebuilder/showAllElementsReducer'
import selectedCustomContainerReducer from './session/pagebuilder/selectedCustomContainerReducer'
import customContainersReducer from './api/webpage/customContainersReducer'
import addSelectedCustomContainerReducer from './session/pagebuilder/addSelectedCustomContainerReducer'

//// BRDOPARA REDUCERS 
import todaysMatchesReducer from './api/brdopara/todaysMatchesReducer'
import leaderBoardsReducer from './api/brdopara/leaderBoardsReducer'
import activeBetsReducer from './api/brdopara/activeBetsReducer'
import userActiveBetsReducer from './api/brdopara/userActiveBetsReducer'
import activeBetsStatisticReducer from './api/brdopara/activeBetsStatisticReducer'
import subscribedBetsReducer from './api/brdopara/subscribedBetsReducer'
import usersStatisticWeeklyReducer from './api/brdopara/usersStatisticWeeklyReducer'
import usersStatisticMonthlyReducer from './api/brdopara/usersStatisticMonthlyReducer'
import usersStatisticOverallReducer from './api/brdopara/usersStatisticOverallReducer'
import userHistoryBetsReducer from './api/brdopara/userHistoryBetsReducer'
import userFavoriteBetsReducer from './api/brdopara/userFavoriteBetsReducer'
import historyBetsReducer from './api/brdopara/historyBetsReducer'
import userGeoLocationReducer from "./session/webpage/userGeoLocationReducer";
import tipstersReducer from "./api/controlpanel/tipstersReducer";
import tipstersAchievementsOptionsReducer from "./api/controlpanel/tipstersAchievementsOptionsReducer";
import inactiveBetsReducer from "./api/brdopara/inactiveBetsReducer";
import usersSupportTicketsReducer from "./api/controlpanel/usersSupportTicketsReducer";

const brdoparaDataReducer = combineReducers({
    leaderBoards: leaderBoardsReducer,
    usersStatisticWeekly: usersStatisticWeeklyReducer,
    usersStatisticMonthly: usersStatisticMonthlyReducer,
    usersStatisticOverall: usersStatisticOverallReducer,
    activeBets: activeBetsReducer,
    inactiveBets: inactiveBetsReducer,
  
    subscribedBets: subscribedBetsReducer,
    userHistoryBets: userHistoryBetsReducer,
    historyBets: historyBetsReducer,
    activeBetsStatistic: activeBetsStatisticReducer,
    userActiveBets: userActiveBetsReducer,
    userFavoriteBets: userFavoriteBetsReducer
})

export default combineReducers({
  webpages: pagesReducer,
  webpagesInfo: pagesInfoReducer,
  modalStatus: modalStatusReducer,
  snackbarStatus: snackbarStatusReducer,
  selectedPage: selectedPageReducer,
  selectedElement: selectedElementReducer,
  copySelectedElement: copySelectedElementReducer,
  drawerStatus: drawerStatusReducer,
  auth: authReducer,
  elements: elementsReducer,
  subscriptions: subscriptionsReducer,
  users: usersReducer,
  activeLanguage: activeLanguageReducer,
  dictionary: dictionaryReducer,
  activeDocumentPage: activeDocumentPageReducer,
  globalElements: globalElementsReducer,
  pageChangeHistory: pageChangeHistoryReducer,
  globalElementsLoaded: globalElementsLoadedReducer,
  elementEventsDisabled: disableElementEventsReducer,
  showAllElements: showAllElementsReducer,
  selectedCustomContainer: selectedCustomContainerReducer,
  customContainers: customContainersReducer,
  addSelectedCustomContainer: addSelectedCustomContainerReducer,
  todaysMatches: todaysMatchesReducer,
  serverInfo: serverInfoReducer,
  scraperInfo: scraperInfoReducer,
  scraperOddsportalIDs: scraperOddsportalIdsReducer,
  userGeoLocation: userGeoLocationReducer,
  brdoparaData: brdoparaDataReducer,
  tipsters: tipstersReducer,
  tipstersAchievementsOptions: tipstersAchievementsOptionsReducer,
  usersSupportTickets: usersSupportTicketsReducer,
});
