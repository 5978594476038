import _ from 'lodash'

const mapKeysDeep = (obj, newObj={}, selectedKey=null) => {
    
    for(let [key, value] of Object.entries(obj)){
        let newKey = selectedKey ? selectedKey+'/'+key : '/'+key
        if(_.isObject(value)){

            delete newObj[selectedKey]
            if(Object.keys(value).length !== 0){
                newObj[newKey] = value
                mapKeysDeep(value, newObj, newKey)
            }
           
        }else{
            delete newObj[selectedKey]
            if(value || value === 0) newObj[newKey] = value
        }
    }
}

const objectToFirebaseUpdateObject = (object)=>{
    let updateObject = {}
    mapKeysDeep(object, updateObject)

    return updateObject
}


export default objectToFirebaseUpdateObject