import * as defaultCss from '../../defaultCss'

/*
####################################################
###################################################
##################VARIABLES#######################

name(String) - container description
childrens(Array) - contains ids of possible elementChildren inputs
properties(Array) - container properties
  label - property description
  type[text, selection, selectionArray, checkbox] - input type
  id - input id
  value - input value
  if(type === selection) values - select input values
  default - default input value
  linkedTo - id of input that it is linked to
  linkedValue - value shown in selection
  valuesQuery - ?
  basic - basic inputs (for switching between advanced and normal mode)
  groupBy[content, look, function, image] - for easier grouping of inputs inside forms
*/

const brdopara_circle_box_element = {
  name: "Circle box element",

  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Name", type:"text", id: "title", value: "", default: "", groupBy: "content"
    },
    {
      label:"Global element", type:"checkbox", id: "global", value: false, default: false, groupBy: "function"
    },
  ],

  containedInElements: [
    "box_element",
    "grid_item_element"
  ],
  
}

const brdopara_home_element = {
  name: "Home element",

  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    
  ],

  containedInElements: [
    "box_element",
    "grid_item_element"
  ],
  
}

const brdopara_list_box_element = {
  name: "List box element",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Element style", type:"elementStyle", id: "elementStyle", values: {}, default: {}, groupBy: "style"
    },
    {
      label:"Max rows displayed", type:"selection", id: "maxRowsDisplayed",  values: [5, 10, 15, 20, 25], default: 10, groupBy: "function"
    },
    {
      label:"Num of data fetched on init", type:"selection", id: "numOfDataOnFirstLoad",  values: [ 10, 20, 30, 40, 50], default: 30, groupBy: "function"
    },
    {
      label:"Expand table data", type:"checkbox", id: "expandMoreData", value: true, default: true, groupBy: "function"
    },
    {
      label:"Fetch more data", type:"checkbox", id: "fetchAdditionalData", groupBy: "function", value: false, default: false,
    },
    {
      label:"Enable search", type:"checkbox", id: "enableSearch", groupBy: "function", value: false, default: false,
    },
    {
      label:"Enable sort", type:"checkbox", id: "enableSort", groupBy: "function", value: false, default: false,
    },
    {
      label: "Container height multiplier", type:"selection", id: "containerHeightMultiplier", values: [ 0.25 ,0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3], value: 1, default: 1, groupBy: "look"
    },
    
    {
      label: "Data type", 
      type:"selection", 
      id: "dataType", 
      values: [ 
        "leaderBoardWeekly",
        "leaderBoardMonthly",
        "leaderBoardOverall",
        "allActiveBetsInfo",
        "myProfile_subscribedActiveBets",
        "myProfile_userHistoryBets",
        "myProfile_favoriteActiveBets",
        "userProfile_userActiveBetsInfo",
        "userProfile_userHistoryBets",
        "bets_allActiveBetsInfo",
        "bets_allHistoryBets"
      ], 
      default: "bets", 
      groupBy: "function"
    },
  ],
  containedInElements: [
    "box_element",
    "grid_item_element"
  ],
  
}
const brdopara_bets_list_box_element = {
  name: "Bets list box element",
  properties:[
    {
      label:"Element style", type:"elementStyle", id: "elementStyle", values: {}, default: {}, groupBy: "style"
    },
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Max rows displayed", type:"selection", id: "maxRowsDisplayed",  values: [5, 10, 15, 20, 25], default: 10, groupBy: "function"
    },
    {
      label:"Num of data fetched on init", type:"selection", id: "numOfDataOnFirstLoad",  values: [ 10, 20, 30, 40, 50], default: 30, groupBy: "function"
    },
    {
      label:"Expand table data", type:"checkbox", id: "expandMoreData", value: true, default: true, groupBy: "function"
    },
    {
      label:"Fetch more data", type:"checkbox", id: "fetchAdditionalData", groupBy: "function", value: false, default: false,
    },
    {
      label:"Enable search", type:"checkbox", id: "enableSearch", groupBy: "function", value: false, default: false,
    },
    {
      label:"Enable sort", type:"checkbox", id: "enableSort", groupBy: "function", value: false, default: false,
    },
  
    
    {
      label: "Data type", 
      type:"selection", 
      id: "dataType", 
      values: [ 
        "allActiveBetsInfo",
      ], 
      default: "bets", 
      groupBy: "function"
    },
  ],
  containedInElements: [
    "box_element",
    "grid_item_element"
  ],
  
}

const brdopara_register_user_element = {
  name: "Register box element",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
  ],
  containedInElements: [
    "box_element",
    "grid_item_element"
  ],
  
}

const brdopara_user_settings_element = {
  name: "User settings element",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
  ],
  containedInElements: [
    "box_element",
    "grid_item_element"
  ],
  
}

const brdopara_active_bet_recommendation_element = {
  name: "Active bet recommendation",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label: "Container height percentage", type:"selection", id: "heightPercentage", values: [ 0.25 ,0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3], value: 1, default: 1, groupBy: "look"
    },
  ],
 
  containedInElements: [
    "box_element",
    "grid_item_element"
  ],
  
}


const brdopara_login_box_element = {
  name: "Login box element",
 
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label: "Type", type:"selection", id: "formType", values: [ "register", "login"], value: "register", default: "register", groupBy: "function"
    },
  ],
  
  containedInElements: [
    "box_element",
    "grid_item_element"
  ],
  
}

const brdopara_add_bet_element = {
  name: "Add bet element",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Title", type:"text", id: "title", value: "", default: "", groupBy: "content"
    },
  ],
  containedInElements: [
    "box_element",
    "grid_item_element"
  ],
  
}

const brdopara_profile_element = {
  name: "Profile element",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
   
  ],
  containedInElements: [
    "box_element",
    "grid_item_element"
  ],
  
}

const brdopara_bet_info = {
  name: "Bet info element",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
   
  ],
  containedInElements: [
    "box_element",
    "grid_item_element"
  ],
  
}

const brdopara_history_bet_info = {
  name: "Bet history info element",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
   
  ],
  containedInElements: [
    "box_element",
    "grid_item_element"
  ],
  
}

const brdopara_account_creation_element = {
  name: "Account creation element",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
   
  ],
  containedInElements: [
    "box_element",
    "grid_item_element"
  ],
  
}


const brdopara_button_drawer_element = {
  name: "Button drawer element",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    
    {
      label:"Element style", type:"elementStyle", id: "elementStyle", values: {}, default: {}, groupBy: "style"
    },
    {
      label:"Label", type:"text", id: "label", value: "", default: "", basic: true, groupBy: "content"
    },
    {
      label:"Avatar", type:"text", id: "avatar", value: "", default: "", basic: true, groupBy: "content"
    },
    
    {
      label:"Icon name", type:"text", id: "icon", value: "", default: "", basic: true, groupBy: "content"
    },
    {
      label: "Button type", type:"selection", id: "buttonType", values: [ "small", "large"], default: "button", groupBy: "function"
    },
    {
      label: "Variant", type:"selection", id: "variant", values:["contained", "outlined", "text"], default: "contained", groupBy: "look"
    },
    {
      label: "Text align", type:"selection", id: "align", values: [ "center", "left", "right"], default: "center", groupBy: "look"
    },
    {
      label: "Color", type:"selection", id: "color", values: [ "inherit", "primary", "secondary", "default"], default: "primary", groupBy: "look"
    },
    {
      label:"Redirect to", id: "redirect", type:"text",  value: "", default: "", basic: true, groupBy: "function"
    },
    {
      label:"Go to", type:"text", id: "goToPath", values: "", default: "", basic: true, groupBy: "function"
    },
    {
      label: "On click function", type:"selection", id: "onClickFunction", values: ["logOut"], default: "", groupBy: "function"
    },
    {
      label:"Global element", type:"checkbox", id: "global", value: false, default: false, groupBy: "function"
    },

  ],
  containedInElements: [
    "box_element",
    "grid_item_element"
  ],
  
}

const brdopara_navbar_element = {
  name: "Navbar element",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Home page element", type:"checkbox", id: "homePage", value: false, default: false, groupBy: "function"
    },
  ],
  containedInElements: [
    "box_element",
    "grid_item_element"
  ],
  
}

const brdopara_carousel_element = {
  name:"Brdopara carousel element",

  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
  ],

  containedInElements: [
    "box_element",
    "grid_item_element"
  ],
 
  containsElements:[
    "box_element"
  ],
  
}


export default {
  brdopara_register_user_element,
  brdopara_navbar_element,
  brdopara_button_drawer_element,
  brdopara_list_box_element,
  brdopara_circle_box_element,
  brdopara_add_bet_element,
  brdopara_profile_element,
  brdopara_account_creation_element,
  brdopara_bet_info,
  brdopara_login_box_element,
  brdopara_user_settings_element,
  brdopara_history_bet_info,
  brdopara_active_bet_recommendation_element,
  brdopara_bets_list_box_element,
  brdopara_carousel_element,
  brdopara_home_element
}
