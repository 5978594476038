import {
    FETCH_ACTIVE_BETS_STATISTIC,
    ADD_BETS_STATISTIC_COUNTER,
    FETCH_ACTIVE_BETS_SPORT_COUNTERS
} from "../../../actions/types";
  
  
export default (state = "loading", action) => {
    //initialize todays matches object on first load
    let activeBetsStatistic = state !== "loading" ? {...state} : {competitions:{}, matches:{}, sports:{}, sportsCounters:{}, competitionsCounters:{}, matchesCounters:{}}
    
    switch (action.type) {

        case FETCH_ACTIVE_BETS_STATISTIC:
             activeBetsStatistic = action.payload || activeBetsStatistic
             return activeBetsStatistic
        case FETCH_ACTIVE_BETS_SPORT_COUNTERS:
            activeBetsStatistic.sportsCounters = action.payload
            return activeBetsStatistic
        case ADD_BETS_STATISTIC_COUNTER:
            activeBetsStatistic.sports[action.bet.sportId] = action.bet.sportId
            activeBetsStatistic.competitions[action.bet.competitionId] = action.bet.competition
            activeBetsStatistic.matches[action.bet.matchId] = action.bet.match

            if(!activeBetsStatistic.sportsCounters) activeBetsStatistic.sportsCounters = {}
                if(activeBetsStatistic.sportsCounters[action.bet.sportId]) activeBetsStatistic.sportsCounters[action.bet.sportId] += 1
            else activeBetsStatistic.sportsCounters[action.bet.sportId] = 1

            if(!activeBetsStatistic.competitionsCounters) activeBetsStatistic.competitionsCounters = {}
            if(activeBetsStatistic.competitionsCounters[action.bet.sportId]){
                if(activeBetsStatistic.competitionsCounters[action.bet.sportId][action.bet.competitionId])activeBetsStatistic.competitionsCounters[action.bet.sportId][action.bet.competitionId] += 1
                else activeBetsStatistic.competitionsCounters[action.bet.sportId][action.bet.competitionId] = 1
            }else activeBetsStatistic.competitionsCounters[action.bet.sportId] = {[action.bet.competitionId]: 1}

            if(!activeBetsStatistic.matchesCounters) activeBetsStatistic.matchesCounters = {}
            if(activeBetsStatistic.matchesCounters[action.bet.sportId]){
                if(activeBetsStatistic.matchesCounters[action.bet.sportId][action.bet.matchId])activeBetsStatistic.matchesCounters[action.bet.sportId][action.bet.matchId] += 1
                else activeBetsStatistic.matchesCounters[action.bet.sportId][action.bet.matchId] =  1
            }else activeBetsStatistic.matchesCounters[action.bet.sportId] = {[action.bet.matchId]: 1}

            return activeBetsStatistic
        default:
            return state
    }
};