import {
    FETCH_USER_ACTIVE_BETS,
} from "../../../actions/types";
  
  
export default (state = "loading", action) => {
    if(!action.payload)return state
    //initialize todays matches object on first load
    let subscribedBets = state !== "loading" ? {...state} : {}
    switch (action.type) {
        case FETCH_USER_ACTIVE_BETS:   
            subscribedBets={...subscribedBets.all, ...action.payload} 
            return subscribedBets;
        default:
            return state
    }
};