import _ from 'lodash'

import {
  SET_AUTH,
  SET_AUTH_ACCESS_TOKEN,
  UPDATE_AUTH_USER_INFO,
  UPDATE_AUTH_USER_SUBSCRIPTION
} from "./../../actions/types";

export default (state = "loading", action) => {
  switch (action.type) {
    case SET_AUTH:
      return action.payload;
    case SET_AUTH_ACCESS_TOKEN:
      state.accessToken = action.payload
      return {...state}
    case UPDATE_AUTH_USER_INFO:
      if(state.userInfo) state.userInfo = {...state.userInfo, ...action.payload}
      else state.userInfo = action.payload
      return {...state}
    case UPDATE_AUTH_USER_SUBSCRIPTION:
      let newState = _.cloneDeep(state)
      if(newState.userInfo.metadata.subscriptions) newState.userInfo.metadata.subscriptions = {...newState.userInfo.metadata.subscriptions, [action.tipsterId]: action.payload}
      else newState.userInfo.metadata.subscriptions = {[action.tipsterId]: action.payload}

      return newState
    default:
      return state;
  }
};
