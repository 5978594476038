
import {
  FETCH_SERVER_INFO,
} from "../../../actions/types";


export default (state = "loading", action) => {
  switch (action.type) {
    case FETCH_SERVER_INFO:
      return action.payload;
    default:
      return state;
  }
};
