import React from 'react';
import * as actions from '../../../redux/actions'

import classNames from 'classnames'

import { connect } from "react-redux";

import {withRouter} from 'react-router'

import { withStyles, useTheme, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ButtonBase from '@material-ui/core/ButtonBase';
import TextField from '@material-ui/core/TextField';
import Zoom from '@material-ui/core/Zoom';

import { Typography } from '@material-ui/core';



const styles = theme => ({
    root:{
        width: "100%",
        maxWidth: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
    },
    container: {
        height: "100%",
        borderRadius: 4,
    },
    buttonSocialGoogle:{
        width: "100%",
        color: "white",
        height: 75,
        background: "rgb(222,82,70)",
        fontSize: "1.7em",
        borderRadius: 4,

        '&:hover':{
            background: "#AB3B32",
            boxShadow: theme.shadows[5]
        }
    },
    buttonSocialFacebook:{
        width: "100%",
        height: 75,
        color: "white",
        fontSize: "1.7em",
        background: "#4267B2",
        borderRadius: 4,

        '&:hover':{
            background: "#375695",
            boxShadow: theme.shadows[5]
        }
  
    },
    buttonTypography:{
        fontSize: "0.8em",
        paddingLeft: theme.spacing(1)
    }

});

const useTextFieldStyles = makeStyles( theme => ({
    root:{
       width:"100%",
    },
    inputDisabled:{
       opacity: 0.3, 
    },
    inputError:{
        color : `${theme.palette.error.main} !important`,
        borderColor: `${theme.palette.error.main} !important`,
    },
  
    cssLabel: {
        color : `${theme.palette.text.secondary} !important`,
        fontWeight: 200,
    },
    
    cssOutlinedInput: {
        color: `${theme.palette.text.secondary} !important`,
        borderRadius: 4,
        
        fontWeight: 200,

        '&$cssFocused $notchedOutline': {
            color: `${theme.palette.text.secondary} !important`,
            borderColor: `${theme.palette.text.secondary} !important`,
        },

    },

    cssOutlinedInputError: {
        color: `${theme.palette.error.main} !important`,
        borderRadius: 4,
        
        fontWeight: 200,

        '&$cssFocused $notchedOutline': {
            color: `${theme.palette.error.main} !important`,
            borderColor: `${theme.palette.error.main} !important`,
        },

    },
    
    cssFocused: {
        color: `${theme.palette.text.secondary} !important`,
    },

    notchedOutline: {
        borderWidth: '1px',
        borderColor: `${theme.palette.text.secondary} !important`,
        fontWeight: 200,
        color: `${theme.palette.text.secondary} !important`,
    },

    clearIndicator:{
        color: theme.palette.error.main
    },

    popupIndicator:{
        color: theme.palette.text.secondary,
        marginRight: 4,
    },
    

}))


const CustomInput = ({label, type = "text", error, onChange, disabled, value, placeholder})=>{
    const classes = useTextFieldStyles()

  

    const handleChange = (e) => {
       
        let value = e.target.value
        onChange(value)
    }

    return (
        <TextField
           
            className={classNames(classes.root, {[classes.inputDisabled]: disabled})}
            disabled={disabled}
            required
            label={label}
            value={value}
            variant="outlined"
            error={error}
            onChange={handleChange}
            type={type}
            InputLabelProps={{
            classes:{
                root: classes.cssLabel,
                focused: classes.cssFocused,
            },
            }}
            InputProps={{
                className: classes.input,
                classes:{
                    root: classNames(classes.cssOutlinedInput, {[classes.inputError]: error}),
                    focused: classes.cssFocused,
                    notchedOutline: classNames(classes.notchedOutline , {[classes.inputError]: error}),
                },
           
            }}
            
        />
    )
}


function LoginBoxElement({
    classes,
    style,
    elementStyle,
    boxStyle,
    elementClasses,
    edit,
    auth,
    history,
    location,
    
    formType="register",
    ...props
}){

    const theme = useTheme()

    const [loading, setLoading] = React.useState(false)

    const [email, setEmail] = React.useState("")
    const [password, setPassword] = React.useState("")

    const [inputError, setInputError] = React.useState(false)


    React.useEffect(()=>{
        
        if(!edit && auth && auth.userInfo){
            history.replace("/")
        }

    
    },[auth, history])

    const handleEmailChange = (value)=>{
        setEmail(value)
    }

    const handlePasswordChange = (value)=>{
        setPassword(value)
    }


    const handleSocialLogin = (type) => () => {
        

        if(type === "google"){
            props.authenticateUserWithGoogle(type, history)
        }else if(type === "facebook"){
            props.authenticateUserWithFacebook(type, history)
        }
    }

    const handleUserLogin = ()=>{
      
        if(!email || !password){
            setInputError(true)
        }else{
            setLoading(true)
            props.authenticateUserWithEmail({email, password, setLoading, history})
        }
    }

    return (
        <Box
            className={classes.root}
            p={1}
        >
            <Zoom in={true} timeout={400}>
                <Box flexGrow="1" width="100%" height="100%" display="flex"  flexDirection="column" justifyContent="center" alignItems="center" >
                    <Box 
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius={4}
                        height={75}
                        fontSize="clamp(2em, 2vw, 3em)"
                        width="100%"
                        bgcolor="background.transparent"
                        color="white"
                    >
                    
                        Prijava
                    </Box>
                    <Box p={3} mt={1} width="100%" display="flex" flexDirection="column" justifyContent="space-between" borderRadius={4} bgcolor="background.transparent">
                        <Box width="100%" pb={2}>
                            <CustomInput variant="outlined" onChange={handleEmailChange} value={email} label="E-mail" placeholder="" error={inputError && !Boolean(email)}/>
                        </Box>
                        <Box width="100%" pb={2}>
                            <CustomInput variant="outlined"  onChange={handlePasswordChange} value={password} label="Geslo" placeholder="" type="password" error={inputError && Boolean(password)}/>
                        </Box>
                        <Box display="flex" justifyContent="center" position="relative" color="white" >
                            <Box position="absolute" top="0" bottom="0" margin="auto" width="100%"  height="1px" bgcolor="primary.main" />
                            <Box position="relative" bgcolor="background.primary" px={1}>
                                <Typography>
                                    ali
                                </Typography>
                            </Box>
                            
                        </Box>

                        <Box pt={2} display="flex" flexDirection="row" justifyContent="space-between">
                            <Box  width="calc(50% - 8px)">
                                <ButtonBase onClick={handleSocialLogin("google")} className={classes.buttonSocialGoogle}>
                                    <Box position="absolute" left="0" pl={2} display="flex" alignItems="center">
                                       
                                    </Box>
                                    <Typography className={classes.buttonTypography} variant="body1">Google</Typography>
                                </ButtonBase>
                            </Box>
                            <Box width="calc(50% - 8px)">
                                <ButtonBase onClick={handleSocialLogin("facebook")} className={classes.buttonSocialFacebook}>
                                    <Box position="absolute" left="0" pl={2} display="flex" alignItems="center">
                                       
                                    </Box>
                                    <Typography className={classes.buttonTypography} variant="body1"> Facebook</Typography>
                                </ButtonBase>
                            </Box>
                        </Box>
                        <Box width="100%" pt={2} color="white">
                            <Button onClick={handleUserLogin} disabled={!Boolean(email) || !Boolean(password)} height={75} style={{color:"white"}}> 
                                POTRDI
                            </Button>
                        </Box>
                    </Box>
                </Box>  
                
            </Zoom>

            
        </Box>
    )

}

LoginBoxElement.defaultProps = {


  elementClasses: [],
  elementStyle: {}, // style object of element from database
  style: {}, // style object is pushed to element if there is active animation present
  
};

const mapStateToProps = ({auth}) => {
    return {auth}
  }

export default withRouter(connect(mapStateToProps, actions)(withStyles(styles)(LoginBoxElement)))
