import {
  SET_ACTIVE_MODAL,
  CLOSE_ACTIVE_MODAL,
  SET_ACTIVE_DRAWER,
  SET_PREVIOUS_MODAL,
  SET_MODAL_DATA,
} from './../../types'

export const setActiveModal  = (modalId) => async dispatch =>{

  dispatch({
    type: SET_ACTIVE_MODAL,
    payload: modalId
  })
}

export const setActiveModalWithData  = (modalId, data) => async dispatch =>{

  dispatch({
    type: SET_ACTIVE_MODAL,
    payload: modalId
  })

  dispatch({
    type: SET_MODAL_DATA,
    payload: data
  })


}



export const closeActiveModal  = () => async dispatch =>{

  dispatch({
    type: CLOSE_ACTIVE_MODAL,
    payload: []
  })
}

export const setPreviousActiveModal  = () => async dispatch =>{

  dispatch({
    type: SET_PREVIOUS_MODAL,
  })
}


export const setActiveDrawer = (drawerId) => dispatch =>{
  dispatch({
    type: SET_ACTIVE_DRAWER,
    payload: drawerId
  })

}
