
import { SET_DISABLE_ELEMENT_EVENTS } from "./../../../actions/types";

// disable all synthetic events on all elements
export default (state = false, action) => {
  switch (action.type) {
    case SET_DISABLE_ELEMENT_EVENTS:
      return action.payload;
    default:
      return state;
  }
};
