import * as firebase from './../../../../config/firebase'
import logger from  './../../../../helpers/logger'

import {
    FETCH_SERVER_INFO,
    FETCH_SCRAPER_INFO,
    UPDATE_SCRAPER_SETTINGS,
    SET_ACTIVE_SNACKBAR,
    FETCH_ODDSPORTALIDS,
    UPDATE_ODDSPORTALIDS,
    FETCH_TIPSTERS_ACHIVEMENTS_OPTIONS,
    ADD_TIPSTERS_ACHIVEMENTS_OPTION,
    UPDATE_TIPSTERS_ACHIVEMENTS_OPTION,
    DELETE_TIPSTERS_ACHIVEMENTS_OPTION,
    CLOSE_ACTIVE_MODAL,
    UPDATE_TIPSTER_ACHIEVEMENTS,
    FETCH_TIPSTERS,
    FETCH_USERS_SUPPORT_TICKETS,
    REMOVE_USERS_SUPPORT_TICKET
  } from './../../types'

export const startServerTask =  (options, setLoading) => async dispatch => {
  
    try{
        logger('api_call', `startServerTask/${options.task}`)
        dispatch({
            type: SET_ACTIVE_SNACKBAR,
            name: "startTaskSnackbar",
            status: "info",
            label: `Task ${options.task} has started.`,
        })

        const fetchServerStatus = firebase.cloudFunction.httpsCallable('startServerTask')
        await fetchServerStatus(options)

        setLoading(false)

        dispatch({
            type: SET_ACTIVE_SNACKBAR,
            name: "endTaskSnackbar",
            status: "info",
            label: `Task ${options.task} has been finished.`,
        })
     
    }catch(err){
        logger('exception', "startServerTask", {err})
        setLoading(false)
        dispatch({
            type: SET_ACTIVE_SNACKBAR,
            name: "endTaskSnackbar",
            status: "error",
            label: `Task ${options.task} return error. ${err.message}`,
        })
    }
}

export const fetchServerInfo = () => async dispatch => {
    
    try{
        logger('api_call', "fetchServerInfo")
        const fetchServerStatus = firebase.cloudFunction.httpsCallable('fetchServerStatus')
        const result = await fetchServerStatus()
        
        dispatch({
            type: FETCH_SERVER_INFO,
            payload: result.data
        })

    }catch(err){
        logger('exception', "fetchServerInfo", {err})
    }
}


export const fetchScraperInfo = () => async dispatch => {
    
    try{
        logger('api_call', "fetchScraperInfo")
        let scraperInfo = await firebase.databaseRef.child('scraper').once('value')
        scraperInfo = scraperInfo.val()
        
        dispatch({
            type: FETCH_SCRAPER_INFO,
            payload: scraperInfo
        })

    }catch(err){
        
        logger('exception', "fetchScraperInfo", {err})
        
    }
     
}

export const updateScraperSettings = (scraperSetting, values)=> async dispatch => {
 
    try{    
        logger('api_call', "updateScraperSettings")
        await firebase.databaseRef.child('scraper').child("settings").child(scraperSetting).update(values)
        
        dispatch({
            type: UPDATE_SCRAPER_SETTINGS,
            scraperSetting,
            payload: values
        })

        dispatch({
            type: SET_ACTIVE_SNACKBAR,
            name: "updateScraperInfoSnackbar",
            status: "info",
            label: `Scraper info has been updated successfully.`,
        })

    }catch(err){

        dispatch({
            type: SET_ACTIVE_SNACKBAR,
            name: "updateScraperInfoSnackbar",
            status: "error",
            label: "Error: " + err.message,
        })
        
        logger('exception', "updateScraperSettings", {err})
        
    }
}

export const addSportScrapperSettings = ({sport, status})=> async dispatch => {
 
    await firebase.databaseRef.child('scraper').child("settings").update({
        [`sports/${sport}`]: status, 
       
    })
}

export const addRegionScrapperSettings = ({sport, region, regionLabel, flagIconFile, regionStatus})=> async dispatch => {
    let iconUrl = null
    if(flagIconFile){
        let uploadTask = await firebase.storageRef.child('countriesIcon').child(flagIconFile.name).put(flagIconFile, {cacheControl: 'public, max-age=31536000',})
        iconUrl = await uploadTask.ref.getDownloadURL()
    }
    

    await firebase.databaseRef.child('scraper').child("settings").update({
        [`regions/${sport}/${region}`]: regionStatus, 
        [`regionsList/${region}`]: {
            icon: iconUrl,
            label: regionLabel
        }
    })
}

export const addCompetitionScrapperSettings = ({sport, region, competition, competitionLabel, competitionStatus})=> async dispatch => {
     
    await firebase.databaseRef.child('scraper').child("settings").update({
        [`competitions/${sport}/${region}/${competition}`]: competitionStatus, 
        [`competitionsList/${competition}/`]: competitionLabel || competition, 
    })

}

export const fetchOddsportalIds = ()=> async dispatch => {
    
    try{    
        logger('api_call', "fetchOddsportalIds")
        let res = await firebase.databaseRef.child('scraper').child("oddsportalIds").once('value')
        
        dispatch({
            type: FETCH_ODDSPORTALIDS,
            payload: res.val()
        })
       

    }catch(err){

        logger('exception', "fetchOddsportalIds", {err})
        
    }
}

export const updateOddsportalIds = (sport) => async dispatch => {
    
    try{
        logger('api_call', "updateOddsportalIds")
       
        const oddsportalIds = firebase.cloudFunction.httpsCallable('startServerTask')
        const result = await oddsportalIds({task: "fetchOddsportalIds", sport})
        console.log(result)
        dispatch({
            type: UPDATE_ODDSPORTALIDS,
            sport,
            payload: result
        })
    }catch(err){
        dispatch({
            type: SET_ACTIVE_SNACKBAR,
            name: "updateScraperInfoSnackbar",
            status: "error",
            label: "Error: " + err.message,
        })
        logger('exception', "updateOddsportalIds", {err})
    }
}


export const fetchTipstersAchievementsOptions = ()=> async dispatch => {
    logger('api_call', "fetchTipstersAchievementsOptions")

    let tipstersAchievementsOptions = await firebase.databaseRef.child('tipstersAchievementsOptions').once('value')
    let tipstersAchievementsOptionsInfo = await firebase.databaseRef.child('tipstersAchievementsOptionsInfo').once('value')

    tipstersAchievementsOptionsInfo = tipstersAchievementsOptionsInfo.val()
    tipstersAchievementsOptions= tipstersAchievementsOptions.val()

    for( let language in tipstersAchievementsOptionsInfo){
        
        for( let tipstersAchievementsOption in  tipstersAchievementsOptionsInfo[language]){

            tipstersAchievementsOptionsInfo[language][tipstersAchievementsOption] = {
                ...tipstersAchievementsOptionsInfo[language][tipstersAchievementsOption],
                ...tipstersAchievementsOptions[tipstersAchievementsOption]
            }

        }

    }


    dispatch({
        type: FETCH_TIPSTERS_ACHIVEMENTS_OPTIONS,
        payload: tipstersAchievementsOptionsInfo
    })
}

export const deleteTipstersAchievementsOptions = ({key})=> async dispatch => {
    logger('api_call', "deleteTipstersAchievementsOptions")

    await firebase.databaseRef.update({
        [`tipstersAchievementsOptions/${key}`]: null,
        [`tipstersAchievementsOptionsInfo/SI/${key}`]: null,
        [`tipstersAchievementsOptionsInfo/SR/${key}`]: null,
        [`tipstersAchievementsOptionsInfo/HR/${key}`]: null,
        [`tipstersAchievementsOptionsInfo/EN/${key}`]: null,
    }) 
   
    dispatch({
        type: DELETE_TIPSTERS_ACHIVEMENTS_OPTION,
        language: "SI",
        key
    })
}

export const updateTipstersAchievementsOption = ({label, icon, key, description, how, conditions, timespan, language})=> async dispatch => {
    logger('api_call', "updateTipstersAchievementsOption")
    let iconUrl = null
    if(typeof icon !== "string"){
        let uploadTask = await firebase.storageRef.child('icons').child(icon.name).put(icon, {cacheControl: 'public, max-age=31536000',})
        iconUrl = await uploadTask.ref.getDownloadURL()
    }else{
        iconUrl = icon
    }
   
    await firebase.databaseRef.update({
        [`tipstersAchievementsOptionsInfo/${language}/${key}`]:{
            description,
            how,
            conditions,
            timespan
        }, 
        [`tipstersAchievementsOptions/${key}`]:{
            label,
            icon: iconUrl,
        }
    })

    dispatch({
        type: UPDATE_TIPSTERS_ACHIVEMENTS_OPTION,
        key,
        language,
        payload: {
            label,
            icon: iconUrl,
            description,
            how,
            conditions,
            timespan
        }
    })

    dispatch({
        type: CLOSE_ACTIVE_MODAL,
        payload: ""
    })
}

export const addNewTipstersAchivementsOption = ({label, icon, description, how, conditions, timespan, language})=> async dispatch => {
    logger('api_call', "addNewTipstersAchivementsOption")

    let iconUrl = null

    if(icon){
        let uploadTask = await firebase.storageRef.child('icons').child(icon.name).put(icon, {cacheControl: 'public, max-age=31536000',})
        iconUrl = await uploadTask.ref.getDownloadURL()
    }

    let pushNewRef = firebase.databaseRef.child('tipstersAchievementsOptions').push()
    
    firebase.databaseRef.update({
        [`tipstersAchievementsOptions/${pushNewRef.key}`]:{
            label,
            icon: iconUrl,
        },
        [`tipstersAchievementsOptionsInfo/${language}/${pushNewRef.key}`]:{
            how,
            conditions,
            timespan,
            description
        }

    })


    dispatch({
        type: ADD_TIPSTERS_ACHIVEMENTS_OPTION,
        language,
        payload: {
            [pushNewRef.key]:{
                label,
                icon: iconUrl,
                description,
                how,
                conditions,
                timespan,
            }
            
        }
    })

    dispatch({
        type: CLOSE_ACTIVE_MODAL,
        payload: ""
    })
}


export const updateTipsterAchievements = ({tipsterAchivements, tipsterKey})=> async dispatch => {
    logger('api_call', "updateTipsterAchievements")
    console.log(tipsterAchivements, tipsterKey)
    await firebase.databaseRef.child('users').child(tipsterKey).child('achievements').set(tipsterAchivements)

    dispatch({
        type: UPDATE_TIPSTER_ACHIEVEMENTS,
        key: tipsterKey, 
        payload: tipsterAchivements
    })

    dispatch({
        type: CLOSE_ACTIVE_MODAL,
        payload: ""
    })
}


export const fetchTipstersOnce = () => async dispatch => {
    try{
      logger('api_call', "fetchTipstersOnce")
      //let tipstersSubscriptions = await firebase.tipstersSubscriptionsRef.once("value")
      let tipsters = await firebase.usersRef.orderByChild('type').equalTo(2).once("value")
     
      dispatch({
        type: FETCH_TIPSTERS,
        payload: tipsters.val()
      })
    }catch(err){
      logger('exception', "fetchTipstersOnce", {err})
    }
    
}

export const fetchUnconfirmedTipstersOnce = () => async dispatch => {
    try{
      logger('api_call', "fetchUnconfirmedTipstersOnce")
      //let tipstersSubscriptions = await firebase.tipstersSubscriptionsRef.once("value")
      let tipsters = await firebase.usersRef.orderByChild('confirmed').equalTo(false).once("value")
     
      dispatch({
        type: FETCH_TIPSTERS,
        payload: tipsters.val()
      })
    }catch(err){
      logger('exception', "fetchUnconfirmedTipstersOnce", {err})
    }
    
}

export const addSubscribedUser = (email, type)=> async dispatch => {

  firebase.tipstersSubscriptionsRef.push().set({
    email,
    type,
    datetime: new Date().getTime()
  }).then(function() {
    logger('api_call', "addSubscribedUser")
  }).catch(function(err) {
    logger('exception', "addSubscribedUser", {err})
  });
}

export const fetchUsersSupportTickets = ()=> async dispatch => {
    try{
        logger('api_call', "fetchUsersSupportTickets")
        //let tipstersSubscriptions = await firebase.tipstersSubscriptionsRef.once("value")
        let usersSupportTickets = await firebase.databaseRef.child('usersSupportTickets').once("value")
       
        dispatch({
          type: FETCH_USERS_SUPPORT_TICKETS,
          payload: usersSupportTickets.val()
        })
      }catch(err){
        logger('exception', "fetchUsersSupportTickets", {err})
      }
}

export const removeUsersSupportTickets = ({ticketId, userId})=> async dispatch => {
    try{
        logger('api_call', "fetchUsersSupportTickets")
        //let tipstersSubscriptions = await firebase.tipstersSubscriptionsRef.once("value")
        await firebase.databaseRef.child('usersSupportTickets').child(userId).child(ticketId).remove()
       
        dispatch({
            type: REMOVE_USERS_SUPPORT_TICKET,
            ticketId,
            userId
        })
      }catch(err){
        logger('exception', "fetchUsersSupportTickets", {err})
      }
}