
import {
  FETCH_SCRAPER_INFO,
  UPDATE_SCRAPER_SETTINGS
} from "../../../actions/types";


export default (state = "loading", action) => {
  switch (action.type) {
    case FETCH_SCRAPER_INFO:
      return action.payload;
    case UPDATE_SCRAPER_SETTINGS:
      state.settings = {...state.settings, ...action.payload}
      return  state
    default:
      return state;
  }
};
