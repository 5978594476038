
import { SET_SELECTED_ELEMENT } from "./../../../actions/types";


export default (state = "root", action) => {
  switch (action.type) {
    case SET_SELECTED_ELEMENT:
      return action.payload;
    default:
      return state;
  }
};
