
import { SET_SHOW_ALL_ELEMENTS } from "./../../../actions/types";

// display all elements in pagebuilder view
// called in editWrapper
export default (state = false, action) => {
  switch (action.type) {
    case SET_SHOW_ALL_ELEMENTS:
      return action.payload;
    default:
      return state;
  }
};
