import * as firebase from './../../../../config/firebase'
import logger from  './../../../../helpers/logger'

import {
    FETCH_TODAYS_MATCHES_SPORT_LIST,
    FETCH_TODAYS_MATCHES_COMPETITIONS_LIST,
    FETCH_TODAYS_MATCHES_COMPETITION,
    UPDATE_TODAYS_MATCHES_MATCH_ODDS,
    SET_ACTIVE_SNACKBAR
} from "./../../types";


export const fetchTodaysMatchesSportList =  () => async dispatch => {
    
    try{
        logger('api_call', "fetchTodaysMatchesSportList")
        const results = await firebase.todaysMatchesRef.child('sportsList').once('value')

        dispatch({
            type: FETCH_TODAYS_MATCHES_SPORT_LIST,
            payload: results.val()
        })

        return true
    }catch(err){
        
        logger('exception', "fetchTodaysMatchesSportList", {err})
    }
}

export const fetchTodaysMatchesCompetitionsList =  (sportId) => async dispatch => {
   
    try{
        logger('api_call', "fetchTodaysMatchesCompetitionsList")
        const results = await firebase.todaysMatchesRef.child('sports').child(sportId).child('competitionsList').once('value')

        
        dispatch({
            type: FETCH_TODAYS_MATCHES_COMPETITIONS_LIST,
            sportId,
            payload: results.val()
        })

        return true

    }catch(err){
        logger('exception', "fetchTodaysMatchesCompetitionsList", {err})
    }
}

export const fetchTodaysMatchesCompetition = (sportId, competitionId) => async dispatch => {
    
    try{
        logger('api_call', "fetchTodaysMatchesCompetition")
        const results = await firebase.todaysMatchesRef.child('sports').child(`${sportId}/competitions/${competitionId}`).once('value')

        dispatch({
            type: FETCH_TODAYS_MATCHES_COMPETITION,
            sportId,
            competitionId,
            payload: results.val()
        })

        return true
        
    }catch(err){
        logger('exception', "fetchTodaysMatchesCompetition", {err})
    }
}


export const fetchMatchOdds = ({sportId, competitionId, matchId, matchLink, betType, betTypeOption}) => async dispatch =>{
    
    try{
        logger('api_call', "fetchMatchOdds")
        dispatch({
            type: SET_ACTIVE_SNACKBAR,
            name: "fetchingMatchOddsInfo",
            status: "info"
        })

        const fetchMatchOdds = firebase.cloudFunction.httpsCallable('fetchMatchOdds')
        const result = await fetchMatchOdds({competitionId, sportId, matchId, matchLink, betType, betTypeOption})
      
        dispatch({
            type: UPDATE_TODAYS_MATCHES_MATCH_ODDS,
            sportId,
            competitionId,
            matchId,
            payload: result.data.odds,
            betTypeId: result.data.selectedBetType,
            betTypeOptionId: result.data.selectedBetTypeOption,
        })

      
        return {status: "success"}
    }catch(err){

        dispatch({
            type: SET_ACTIVE_SNACKBAR,
            name: "fetchingMatchOddsError",
            status: "error"
        })

        logger('exception', "fetchMatchOdds", {err})
        
    }
    
    
}
