import {
  SET_AUTH,
  FETCH_USERS,
  FETCH_USER,
  SET_AUTH_ACCESS_TOKEN,
  UPDATE_AUTH_USER_INFO,
  SET_ACTIVE_SNACKBAR,
  CLOSE_ACTIVE_MODAL,
  UPDATE_AUTH_USER_SUBSCRIPTION
} from './../../types'
import logger from  './../../../../helpers/logger'

import docCookies from './../../../../helpers/cookies'
import * as firebase from './../../../../config/firebase'

import {saveFileToFireStore} from './../../supportFunctions'

export const isAuthenticated = ()=> async (dispatch, getState) =>{

  firebase.authRef.onAuthStateChanged(async (user)=>{
    logger('auth', "onAuthStateChanged")
    if (user) {
      
      //console.log(user, document)
      /*
        fetch user info once
      */
      let auth = getState().auth
      if(!auth || !auth.userInfo)user.userInfo = await fetchUserInfoOnce(user.uid)

      if(user.userInfo && user.userInfo.admin){

        //save accessToken to auth user
        let accessToken = docCookies.getItem("gapi_access_token")

        //if accessToken cookie expired, reauthenticate

        user.accessToken = accessToken
      }

      dispatch({
        type: SET_AUTH,
        payload: {...user}
      })
    } else {
      dispatch({
        type: SET_AUTH,
        payload: null
      })
    }
  });

}

export const createNewUser =  ({email, password, setLoading, history}) => async dispatch => {
  try{
    logger('auth', "createNewUser")
    await firebase.authRef.createUserWithEmailAndPassword(email, password)
    
    history.push('/dashboard')

    dispatch({
      type:  SET_ACTIVE_SNACKBAR,
      name: "registrationSuccess",
      status: "success",
    })

  }catch(err){
    logger('exception', "createNewUser", {err})
    if(err.code === "auth/email-already-in-use"){
      dispatch({
        type:  SET_ACTIVE_SNACKBAR,
        name: "registrationErrorMailAlreadyExist",
        status: "error",
      })
    }else{
      dispatch({
        type:  SET_ACTIVE_SNACKBAR,
        name: "registrationError",
        status: "error",
      })
    }

  }
  setLoading(false)
};

export const changeUserPassword = (password) => async dispatch =>{
  
  try{
    logger('auth', "changeUserPassword")

    await firebase.authRef.currentUser.updatePassword(password)

    return {
      status: "success",
      data: {
        message: "Geslo je bilo uspešno spremenjeno!"
      },       
    }
  }catch(err){
    logger('exception', "changeUserPassword", {err})

    return {
      status: "error",
      data: {
        message: "Prišlo je do napake! Odjavi se iz računa in poiskusi ponovno!",
      },       
    }
  }
  
}

export const changeUserEmail = (email) => async dispatch =>{
  
  try{
    logger('auth', "changeUserEmail")

    await firebase.authRef.currentUser.updateEmail(email)

    return {
      status: "success",
      data: {
        message: "E-naslov je bil uspešno spremenjen!"
      },       
    }
  }catch(err){
    logger('exception', "changeUserEmail", {err})

    return {
      status: "error",
      data: {
        message: "Prišlo je do napake! Odjavi se iz računa in poiskusi ponovno!",
      },       
    }
  }
}

export const passwordResetRequest = ({email, history, setLoading}) => async dispatch => {
  
  try{
    logger('auth', "passwordResetRequest")

    await firebase.authRef.sendPasswordResetEmail(email)
    setLoading(false)
    history.replace("/login")

    dispatch({
      type:  SET_ACTIVE_SNACKBAR,
      status: "success",
      name: "passwordResetRequestSuccess"
    })
  }catch(err){
    logger('exception', "passwordResetRequest", {err})
    if(err.code === "auth/user-not-found"){
      dispatch({
        type:  SET_ACTIVE_SNACKBAR,
        status: "error",
        name: "passwordResetErrorEmailNotFound"
      })
    }else{
      dispatch({
        type:  SET_ACTIVE_SNACKBAR,
        status: "error",
        name: "passwordResetError"
      })
    }
  }
}
export const emailVerificationRequest = () => async dispatch => {
  
  try{
    logger('auth', "emailVerificationRequest")

    await firebase.authRef.currentUser.sendEmailVerification()
    
    dispatch({
      type:  SET_ACTIVE_SNACKBAR,
      status: "success",
      name: "emailVerificationRequestSuccess"
    })
  }catch(err){
    logger('exception', "emailVerificationRequest", {err})
   
    dispatch({
      type:  SET_ACTIVE_SNACKBAR,
      status: "error",
      name: "emailVerificationRequestError"
    })
    
  }
}

export const emailVerification = ({actionCode, setEmailVerificationStatus, setLoading}) => async dispatch =>{
 
  try{
    logger('auth', "emailVerification")

    await firebase.authRef.applyActionCode(actionCode)
    setEmailVerificationStatus("success")
    setLoading(false)
  }catch(err){
    logger('exception', "emailVerification", {err})
   
    setEmailVerificationStatus("error")
    setLoading(false)
  }
}

export const passwordResetViaEmailLink = ({actionCode, password, history, setLoading, setEmail}) => async dispatch => {
  
  try{
    logger('auth', "passwordResetViaEmailLink")

    let email = await firebase.authRef.verifyPasswordResetCode(actionCode)

    let resp =  await firebase.authRef.confirmPasswordReset(actionCode, password)
   
    history.replace("/login")

    dispatch({
      type:  SET_ACTIVE_SNACKBAR,
      name: "passwordResetSuccess",
      status: "success",
     
    })

  }catch(err){
    logger('auth', "passwordResetViaEmailLink", {err})
    
    if(err.code === "auth/invalid-action-code"){
      dispatch({
        type:  SET_ACTIVE_SNACKBAR,
        name: "passwordResetActionCodeError",
        status: "error",
      })
    }else{
      dispatch({
        type:  SET_ACTIVE_SNACKBAR,
        name: "passwordResetError",
        status: "error",
      })
    }
    
    
  }
}

export const authenticateUserWithEmail = ({email, password, setLoading}) => async dispatch => {
  

  try{
    logger('auth', "authenticateUserWithEmail")

    let auth = await firebase.authRef.signInWithEmailAndPassword(email, password)

    let user = auth.user
    user.userInfo = await fetchUserInfoOnce(user.uid)

    dispatch({
      type: SET_AUTH,
      payload: {...user}
    })

    dispatch({
      type:  SET_ACTIVE_SNACKBAR,
      name: "loginSuccess",
      status: "success",
    })
  }catch(err){
    logger('exception', "authenticateUserWithEmail", {err})

    dispatch({
      type:  SET_ACTIVE_SNACKBAR,
      name: "loginError",
      status: "error",
    })
  }
}

// @type ---> type of login, admin login or default login
export const reauthenticateUserWithGoogle = (type) => async  dispatch => {
 
  try{
    logger('auth', "reauthenticateUserWithGoogle")

    let provider =  new firebase.authProviderRef();

    if(type === "admin")provider.addScope("https://www.googleapis.com/auth/analytics.readonly");

    let result = await firebase.authRef.signInWithPopup(provider)

    let accessToken = result.credential.accessToken

    ///save accessToken to cookie ... cookie expires in 1h same as accessToken
    docCookies.setItem("gapi_access_token", accessToken, 3600)

    dispatch({
      type: SET_AUTH_ACCESS_TOKEN,
      payload: accessToken
    })
    return accessToken
  }catch(err){
    logger('exception', "reauthenticateUserWithGoogle", {err})
  }

}

// @type ---> type of login, admin login or default login
export const authenticateUserWithGoogle = (type) => async dispatch => {
  
  let provider =  new firebase.authProviderRef();
  if(type === "admin")provider.addScope("https://www.googleapis.com/auth/analytics.readonly");

  firebase.authRef.signInWithPopup(provider).then(async (result) => {
    logger('auth', "authenticateUserWithGoogle")

    let user = result.user;
    user.accessToken = result.credential.accessToken;
    ///save accessToken to cookie ... cookie expires in 1h same as accessToken
    docCookies.setItem("gapi_access_token", user.accessToken, 3600)
   
    dispatch({
      type: SET_AUTH,
      payload: {...user}
    })

    return {success: true}
    // ...
  }).catch(function(error) {
    console.log(error)
    logger('exception', "authenticateUserWithGoogle", {err: error.message})
  });
}

export const authenticateUserWithGoogleRedirect = (type) => async dispatch => {
  logger('auth', "authenticateUserWithGoogleRedirect")

  let provider =  new firebase.authProviderRef();
  if(type === "admin")provider.addScope("https://www.googleapis.com/auth/analytics.readonly");
  firebase.authRef.signInWithRedirect(provider);

  /*
    firebase.authRef.signInWithPopup(provider).then(async (result) => {

      let user = result.user;
      user.userInfo = await fetchUserInfoOnce(user.uid)

      user.accessToken = result.credential.accessToken;

      ///save accessToken to cookie ... cookie expires in 1h same as accessToken
      docCookies.setItem("gapi_access_token", user.accessToken, 3600)

      dispatch({
        type: SET_AUTH,
        payload: user
      })
      return {success: true}
      // ...
    }).catch(function(error) {


      console.log(error)
    });
  */
}

export const fetchGoogleLoginRedirectResult = () => async dispatch => {
  logger('auth', "fetchGoogleLoginRedirectResult")
  let result = await firebase.authRef.getRedirectResult()
  if(result.user && result.credential){

    let user = result.user
    user.accessToken = result.credential.accessToken;
    docCookies.setItem("gapi_access_token", user.accessToken, 3600)

  }

}


export const logOut = ()=> async dispatch => {

  firebase.authRef.signOut().then(function() {
    dispatch({
      type: SET_AUTH,
      payload: "loading"
    })
  }).catch(function(error) {
    // An error happened.
  });
}

export const fetchUsersOnce = () => async dispatch =>{
  logger('api_call', "fetchUsersOnce")

  firebase.usersRef.orderByKey().limitToFirst(10).once("value", snapshot => {
    dispatch({
      type: FETCH_USERS,
      payload: snapshot.val()
    });
  });

}

const fetchUserInfoOnce = async (uid) =>{
  
  try{
    logger('api_call', "fetchUserInfoOnce")
    let result = await firebase.userInfoRef.child(uid).once('value')
    return result.val()

  }catch(err){
    logger('exception', "fetchUserInfoOnce", {err})
  }

}

export const fetchUserById = (uid) => async dispatch =>{
  
  try{
    logger('api_call', "fetchUserById")
    let result = await firebase.userInfoRef.child(uid).once('value')

    dispatch({
      type: FETCH_USER,
      uid,
      payload: result.val()
    });

    return true

  }catch(err){
    logger('exception', "fetchUserById", {err})
  }

}

export const updateUserInfo = ({uid, values})=> async dispatch =>{
  
  try{
    logger('api_call', "updateUserInfo")

    await firebase.usersRef.child(uid).update({...values})
    
    dispatch({
      type: UPDATE_AUTH_USER_INFO,
      payload: values
    })

    dispatch({
      type:  SET_ACTIVE_SNACKBAR,
      name: "updateUser",
      status: "success",
    })

  }catch(err){

    logger('exception', "updateUserInfo", {err})

    dispatch({
      type:  SET_ACTIVE_SNACKBAR,
      name: "updateUserError",
      status: "error",
    })
   
  }
}

export const uploadProfileIcon = ({file, uid}) => async dispatch => {
  try{
    logger('api_call', "uploadProfileIcon")
    await saveFileToFireStore(file.name, file, 'icon', dispatch, uid)
  }catch(err){
    logger('exception', "uploadProfileIcon", {err})
    dispatch({
      type:  SET_ACTIVE_SNACKBAR,
      name: "iconUploadError",
      status: "error",
    })
  }
 
}

export const addSubscription = ({auth, tipsterId}) => async dispatch => {
  try{
    logger('api_call', "addSubscription")
    //console.log({userId: auth.uid, userDisplayName: auth.userInfo.displayName, tipsterId})
    const newSubscription = firebase.cloudFunction.httpsCallable('newSubscription')
    const result = await newSubscription({userId: auth.uid, userDisplayName: auth.userInfo.displayName, tipsterId})
   
    let userInfo = {...auth.userInfo}

    if(userInfo.metadata && userInfo.metadata.subscriptions)
      userInfo.metadata.subscriptions[tipsterId] = result
    else if(userInfo.metadata){
      userInfo.metadata.subscriptions = {[tipsterId]: result}
    }else{
      userInfo = {
        metadata:{
          subscriptions:{
            [tipsterId]: result
          }
        }
      }
    }

    dispatch({
      type: UPDATE_AUTH_USER_INFO,
      payload: userInfo
    })

    dispatch({
      type:  SET_ACTIVE_SNACKBAR,
      name: "addSubscriptionSnackbar"
    })

    dispatch({
      type:  CLOSE_ACTIVE_MODAL,
      payload: []
    })

  }catch(err){
    logger('exception', "addSubscription", {err})

    dispatch({
      type:  SET_ACTIVE_SNACKBAR,
      name: "subscriptionErrorSnackbar"
    })

    dispatch({
      type:  CLOSE_ACTIVE_MODAL,
    })

  }
}

export const updateUserSubscriptions = ({userId, tipsterId, subscription}) => async dispatch => {
  
  try{  
    logger('api_call', "updateUserSubscriptions")

    await firebase.usersRef.child(userId).child("metadata").child("subscriptions").update({[tipsterId]: subscription})
  
    dispatch({
      type: UPDATE_AUTH_USER_SUBSCRIPTION,
      tipsterId,
      payload: subscription
    })

    dispatch({
      type: CLOSE_ACTIVE_MODAL,
      payload: []
    })
    
    dispatch({
      type:  SET_ACTIVE_SNACKBAR,
      payload: "updateSubscriptionSnackbar"
    })

    return true

  }catch(err){
    logger('exception', "updateUseSubscriptions", {err})

    dispatch({
      type:  SET_ACTIVE_SNACKBAR,
      payload: "subscriptionErrorSnackbar"
    })

    dispatch({
      type:  CLOSE_ACTIVE_MODAL,
      payload: []
    })
  }
}